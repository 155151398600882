import { gameService } from "src/shared/api/base/api-instances";

import {
  AssessmentApiGetAssessmentbyCodeRequest,
  AssessmentApiGetAssessmentRequest,
} from "src/generated/game";

export const getAssessmentRequest = (
  params: AssessmentApiGetAssessmentRequest,
) => {
  return gameService
    .makeAssessmentsRequest()
    .getAssessment(params)
    .then((res) => res.data);
};

export const getAssessmentByCode = (
  params: AssessmentApiGetAssessmentbyCodeRequest,
) => {
  return gameService
    .makeAssessmentsRequest()
    .getAssessmentbyCode(params)
    .then((response) => response.data);
};
