import { useUnit } from "effector-react";
import { useTranslation } from "react-i18next";
import { setRatings } from "src/entities/hr/ratings/model/ratings";
import {
  $companyStatuses,
  $employmentStatuses,
  $rejectionReasons,
  $relatedProjects,
  employmentStatusesForEmployeesEnum,
} from "src/entities/public/rating-statuses/model";
import { EPopupName } from "src/shared/components";
import { openPopup } from "src/shared/components/base-popup/model";
import {
  getAvatarUrl,
  getCompetencies,
  getFormattedDatetime,
  splitStrings,
} from "src/shared/helpers";
import {
  Rating,
  RejectionReasonEnum,
  RoleInRelatedProjectEnum,
  StatusCompanyEnum,
  StatusEmploymentEnum,
  StatusesEmploymentDto,
} from "src/generated/social";
import {
  requestPostRejectionStatusFx,
  requestPostRoleInRelatedProjectFx,
  requestPostStatusCompanyFx,
  requestPostStatusEmploymentFx,
  selectUser,
} from "src/shared/components/popups/user-profile-popup/lib/profile";
import { stringsToEnumArray, stringToEnum } from "src/shared/helpers/enums";
import {
  EDataGridTextColor,
  IBaseInputChange,
  IBaseMultiSelectChange,
  IDataGridRow,
} from "src/shared/models";
import { Competence } from "../../../../generated/statistic";
import { getGridCommonColor } from "../../../../shared/helpers/data-grids/getGridCommonColor";
import {
  Permissions,
  useCheckPermission,
} from "src/shared/hooks/useCheckPermission";

export const useGetRatingsRowsItems = () => {
  const employmentStatusesListForEmployee = {
    statusesEmployment: [Object.values(employmentStatusesForEmployeesEnum)][0],
  };

  const employmentStatusesListForEnrollee = useUnit($employmentStatuses);

  const companyStatuses = useUnit($companyStatuses);

  const excludeStatus = [
    StatusCompanyEnum.Student,
    StatusCompanyEnum.Relative,
    StatusCompanyEnum.Employee,
  ];

  const rejectionReasons = useUnit($rejectionReasons);

  const relatedProject = useUnit($relatedProjects);

  const { t } = useTranslation();

  const viewProfilePermission = useCheckPermission(Permissions.RatingsUserView);

  return (ratings: Rating[], indexOffset: number): IDataGridRow[] =>
    ratings.map((item, index): IDataGridRow => {
      let analytics = item.competenceRates.find(
        (i) => i.competence === "analytics",
      );

      let analyticsTenacity = item.competenceRates.find(
        (i) => i.competence === "analyticsTenacity",
      );

      let communication = item.competenceRates.find(
        (i) => i.competence === "communication",
      );

      let communicationTenacity = item.competenceRates.find(
        (i) => i.competence === "communicationTenacity",
      );

      let command = item.competenceRates.find(
        (i) => i.competence === "command",
      );

      let commandTenacity = item.competenceRates.find(
        (i) => i.competence === "commandTenacity",
      );

      let thinking3d = item.competenceRates.find(
        (i) => i.competence === "thinking3d",
      );

      let thinking3dTenacity = item.competenceRates.find(
        (i) => i.competence === "thinking3dTenacity",
      );

      let economics = item.competenceRates.find(
        (i) => i.competence === "economics",
      );

      let economicsTenacity = item.competenceRates.find(
        (i) => i.competence === "economicsTenacity",
      );

      let jurisprudence = item.competenceRates.find(
        (i) => i.competence === "jurisprudence",
      );

      let jurisprudenceTenacity = item.competenceRates.find(
        (i) => i.competence === "jurisprudenceTenacity",
      );

      let microelectronics = item.competenceRates.find(
        (i) => i.competence === "microelectronics",
      );

      let microelectronicsTenacity = item.competenceRates.find(
        (i) => i.competence === "microelectronicsTenacity",
      );

      let indexNumber = index + 1 + indexOffset;

      const employmentStatuses = (item.statusCompany !==
      StatusCompanyEnum.Enrollee
        ? employmentStatusesListForEmployee
        : employmentStatusesListForEnrollee) as unknown as StatusesEmploymentDto;

      const serializedStatusEmployment =
        employmentStatuses.statusesEmployment?.map((employmentStatus) => ({
          id: employmentStatus,
          label: t(`table.ratings.statusEmployment.${employmentStatus}`),
          value: employmentStatus,
        }));

      const serializedStatusCompany = companyStatuses.statusesCompany
        ?.filter((companyStatus) => !excludeStatus.includes(companyStatus))
        .map((companyStatus) => ({
          id: companyStatus,
          label: t(`table.ratings.statusCompany.${companyStatus}`),
          value: companyStatus,
        }));

      const serializedRejectionReason = rejectionReasons.rejectionReasons?.map(
        (rejectionReason) => ({
          id: rejectionReason,
          label: t(`table.ratings.rejectionReason.${rejectionReason}`),
          value: rejectionReason,
        }),
      );

      const serializedRelatedProjects =
        relatedProject.rolesInRelatedProject?.map((related) => ({
          id: related,
          label: t(`table.users.rolesInRelatedProjects.${related}`),
          value: related,
        }));

      const fullName = splitStrings([item.firstName, item.lastName]);

      const changeStatusEmploymentHandler = (payload: IBaseInputChange) => {
        const chosenValue = serializedStatusEmployment.find((element) => {
          return element.value === payload.value;
        });

        setRatings(
          ratings.map((player) =>
            player.playerId === item.playerId
              ? {
                  ...player,
                  statusEmployment: stringToEnum(
                    StatusEmploymentEnum,
                    payload.value,
                  ),
                }
              : player,
          ),
        );

        requestPostStatusEmploymentFx({
          userId: item.playerId,
          status: chosenValue?.id,
        });
      };

      const changeStatusCompanyHandler = (payload: IBaseInputChange) => {
        const chosenValue = serializedStatusCompany.find((element) => {
          return element.value === payload.value;
        });

        setRatings(
          ratings.map((player) =>
            player.playerId === item.playerId
              ? {
                  ...player,
                  statusCompany: stringToEnum(StatusCompanyEnum, payload.value),
                  rejectionReason: RejectionReasonEnum.NoSelected,
                  statusEmployment: StatusEmploymentEnum.NoSelected,
                }
              : player,
          ),
        );

        requestPostStatusCompanyFx({
          userId: item.playerId,
          status: chosenValue?.id,
        });
      };

      const changeRejectionReasonHandler = (payload: IBaseInputChange) => {
        const chosenValue = serializedRejectionReason.find((element) => {
          return element.value === payload.value;
        });

        setRatings(
          ratings.map((player) =>
            player.playerId === item.playerId
              ? {
                  ...player,
                  rejectionReason: stringToEnum(
                    RejectionReasonEnum,
                    payload.value,
                  ),
                }
              : player,
          ),
        );

        requestPostRejectionStatusFx({
          userId: item.playerId,
          status: chosenValue?.id,
        });
      };

      const changeRelatedProjectHandler = (payload: IBaseMultiSelectChange) => {
        setRatings(
          ratings.map((player) =>
            player.playerId === item.playerId
              ? {
                  ...player,
                  roleInRelatedProjects: stringsToEnumArray(
                    RoleInRelatedProjectEnum,
                    payload.value,
                  ),
                }
              : player,
          ),
        );

        requestPostRoleInRelatedProjectFx({
          userId: item.playerId,
          status: stringsToEnumArray(RoleInRelatedProjectEnum, payload.value),
        });
      };

      const desiredSpeciality = item.desiredSpecialty
        ?.split(",")
        .map((speciality) =>
          t(`table.desiredSpeciality.${speciality}`, speciality),
        )
        .join(",");

      const commonCompetencies: Record<string, number>[] = [
        {
          [Competence.Thinking3d]: thinking3d?.rate ?? 0,
        },
        {
          [Competence.Jurisprudence]: jurisprudence?.rate ?? 0,
        },
        {
          [Competence.AnalyticsTenacity]: analyticsTenacity?.rate ?? 0,
        },
        {
          [Competence.CommunicationTenacity]: communicationTenacity?.rate ?? 0,
        },
        {
          [Competence.CommandTenacity]: commandTenacity?.rate ?? 0,
        },
        {
          [Competence.EconomicsTenacity]: economicsTenacity?.rate ?? 0,
        },
        {
          [Competence.JurisprudenceTenacity]: jurisprudenceTenacity?.rate ?? 0,
        },
        {
          [Competence.Thinking3dTenacity]: thinking3dTenacity?.rate ?? 0,
        },
        {
          [Competence.Microelectronics]: microelectronics?.rate ?? 0,
        },
        {
          [Competence.MicroelectronicsTenacity]:
            microelectronicsTenacity?.rate ?? 0,
        },
      ];

      return {
        indexNumber,
        gPlace: item.gPlace,
        id: item.playerId,
        firstName: item.firstName,
        lastName: item.lastName,
        fullName,
        acquisitionChannel: item.acquisitionChannel,
        gender: item.gender,
        groupCode: item.groupCode,
        phone: item.phone,
        email: item.email,
        birthdate: item.birthdate,
        confirmationSingle: {
          checked: item.confirmationSingle,
          hideCheckbox:
            item.firstName === "" || item.lastName === "" || item.email === "",
        },
        lastPlay: getFormattedDatetime(item.lastPlay),
        lastActivity: getFormattedDatetime(item.lastActivity),
        created: getFormattedDatetime(item.createdAt),
        country: item.country,
        date_of_getting_analytics_lvl_1: getFormattedDatetime(
          item.date_of_getting_analytics_lvl_1,
          "dd.MM.yyyy",
        ),
        date_of_getting_analytics_lvl_0_5: getFormattedDatetime(
          item.date_of_getting_analytics_lvl_0_5,
          "dd.MM.yyyy",
        ),
        region: item.region,
        district: item.district,
        school: item.school,
        locality: item.locality,
        studyWorkPlace: item.studyWorkPlace,
        desiredSpecialty: item.desiredSpecialty ? desiredSpeciality : null,
        statusEmployment: {
          forTable: true,
          hideSelect: !item.statusEmployment,
          onChange: changeStatusEmploymentHandler,
          activeItem: item.statusEmployment,
          items: serializedStatusEmployment,
          hideEmptyItem: true,
        },
        statusCompany: {
          placeholder:
            item.statusCompany === null
              ? t("common.select.notSelected")
              : undefined,
          activeItem: item.statusCompany,
          forTable: true,
          hideSelect:
            item.firstName === "" || item.lastName === "" || item.email === "",
          onChange: changeStatusCompanyHandler,
          items: serializedStatusCompany,
          isStatusCompany: true,
          hideEmptyItem: true,
        },
        rejectionReason: {
          placeholder:
            item.rejectionReason === null
              ? t("common.select.notSelected")
              : undefined,
          activeItem: item.rejectionReason,
          forTable: true,
          hideSelect:
            item.firstName === "" ||
            item.lastName === "" ||
            item.email === "" ||
            item.statusCompany !== StatusCompanyEnum.Enrollee ||
            item.statusEmployment !== StatusEmploymentEnum.Rejection,
          onChange: changeRejectionReasonHandler,
          items: serializedRejectionReason,
          hideEmptyItem: true,
        },
        rolesInRelatedProjects: {
          activeItem: item.roleInRelatedProjects,
          hideSelect: !item.roleInRelatedProjects,
          onChange: changeRelatedProjectHandler,
          items: serializedRelatedProjects,
          hideEmptyItem: true,
          canSearch: false,
        },
        avatarProps: {
          alt: fullName,
          fullName,
          customPath: getAvatarUrl(item.playerId),
          userId: item.playerId,
          ...(viewProfilePermission
            ? {
                onClick: () => {
                  openPopup({ name: EPopupName.USER_PROFILE_POPUP });
                  selectUser(item.playerId);
                },
              }
            : {}),
        },
        gRate: item.gRate,
        selected: false,
        analytics: analytics?.rate,
        analyticsTenacity: analyticsTenacity?.rate,
        communication: communication?.rate,
        communicationTenacity: communicationTenacity?.rate,
        command: command?.rate,
        economics: economics?.rate,
        commandTenacity: commandTenacity?.rate,
        desiredStudyPlace: item.desiredStudyPlace
          ? t(
              `userForm.select.desiredStudyPlace.${item.desiredStudyPlace}`,
              item.desiredStudyPlace,
            )
          : null,
        additionalCompetencies: getCompetencies({}, commonCompetencies),
        secretCode: item?.secret_code,
        columnsTextColor: {
          gRate: EDataGridTextColor.BLUE,
          analytics: !analytics?.rate ? EDataGridTextColor.GRAY : null,
          command: !command?.rate ? EDataGridTextColor.GRAY : null,
          communication: !communication?.rate ? EDataGridTextColor.GRAY : null,
          additionalCompetencies: getGridCommonColor(commonCompetencies),
        },
        dateConfirmationSingle: getFormattedDatetime(
          item.dateConfirmationSingle,
          "dd.MM.yyyy",
        ),
      };
    });
};
