import {
  ESimulationArrayParamType,
  ESimulationParamsType,
  SimulationListPlayerMetaParamsItemInterface,
} from "../store/types";

export type TSimulationParamTypeKey =
  | "isNumber"
  | "isString"
  | "isBoolean"
  | "isArray"
  | "isArrayString"
  | "isArrayNumber";

export const getSimulationParamType = (payload: {
  param?: SimulationListPlayerMetaParamsItemInterface;
  templateParam?: {
    type?: ESimulationParamsType;
    subType?: ESimulationArrayParamType;
  };
}): Record<TSimulationParamTypeKey, boolean> => {
  const { param, templateParam } = payload;
  const type: ESimulationParamsType | undefined =
    templateParam?.type || param?.type;
  const subType: ESimulationArrayParamType | undefined =
    templateParam?.subType || param?.items?.type;

  const isNumber: boolean =
    type === ESimulationParamsType.INTEGER ||
    type === ESimulationParamsType.NUMBER;
  const isString: boolean = type === ESimulationParamsType.STRING;
  const isBoolean: boolean = type === ESimulationParamsType.BOOLEAN;
  const isArray: boolean = type === ESimulationParamsType.ARRAY;
  const isArrayString: boolean =
    isArray && subType === ESimulationArrayParamType.STRING;
  const isArrayNumber: boolean =
    isArray &&
    (subType === ESimulationArrayParamType.INTEGER ||
      subType === ESimulationArrayParamType.NUMBER);

  return {
    isNumber,
    isString,
    isBoolean,
    isArray,
    isArrayString,
    isArrayNumber,
  };
};
