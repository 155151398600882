import {
  ChangeEvent,
  FC,
  FocusEvent,
  ReactElement,
  useMemo,
  useState,
} from "react";

import { IBaseInput } from "../../../models/base-input";

import { InputTemplate } from "../input-template/input-template";

interface Props extends IBaseInput {
  hidePartially?: boolean;
}

export const BaseInputText: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  isSmall,
  forDarkBg,
  onChange,
  maxLength,
  required,
  isContextBtnDisabled,
  readOnly,
  onDelete,
  onRefresh,
  hidePartially = false,
  onFocus,
  ...otherProps
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;

    onChange({
      value: target.value,
      name: target.name,
    });
  };

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const displayedValue = useMemo(() => {
    if (isFocused || !hidePartially || !value.length) {
      return value;
    }

    return value.slice(0, 1) + "***" + value.slice(-1);
  }, [value, hidePartially, isFocused]);

  const focusHandler = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  return (
    <InputTemplate
      value={displayedValue}
      onChange={onChangeHandler}
      name={name}
      maxLength={maxLength}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      onRefresh={onRefresh}
      onDelete={onDelete}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      onFocus={focusHandler}
      onBlur={() => setIsFocused(false)}
      {...otherProps}
    />
  );
};
