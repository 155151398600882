import { ChangeEvent, FC, FocusEvent, ReactElement, useState } from "react";
import { getTodayDate } from "../../../helpers/getTodayDate";
import { IBaseInput } from "../../../models/base-input";
import { InputTemplate } from "../input-template/input-template";
import { format } from "date-fns";
import styles from "./base-input-date.module.scss";

interface Props extends IBaseInput {
  min?: string | number;
  max?: string | number;
  hidePartially?: boolean;
}

export const BaseInputDate: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  min,
  max,
  isSmall,
  forDarkBg,
  onChange,
  required,
  isContextBtnDisabled,
  readOnly,
  onRefresh,
  onDelete,
  hidePartially = false,
  onFocus,
  onBlur,
}): ReactElement => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const val = target.value;

    onChange({
      value: val,
      name: target.name,
    });
  };

  const focusHandler = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  return (
    <label className={styles.root}>
      {!isFocused && hidePartially && value && (
        <div className={styles.hiddenValue}>
          <span>
            {format(new Date(value), "dd.MM.yyyy").replace(/\d/g, "*")}
          </span>
        </div>
      )}
      <InputTemplate
        type="date"
        value={value}
        min={min || "1900-01-01"}
        max={max || getTodayDate()}
        onChange={onChangeHandler}
        name={name}
        disabled={disabled}
        error={error}
        isSmall={isSmall}
        forDarkBg={forDarkBg}
        required={required}
        placeholder={placeholder}
        isContextBtnDisabled={isContextBtnDisabled}
        readOnly={readOnly}
        onRefresh={onRefresh}
        onDelete={onDelete}
        onFocus={focusHandler}
        onBlur={() => setIsFocused(false)}
        className={styles.input}
      />
    </label>
  );
};
