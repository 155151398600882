import React from "react";
import { useTranslation } from "react-i18next";
import { BaseButton, TextWithIcon } from "src/shared/components";
import {
  DataGridMobileFiltersButton,
  DataGridMobileSortButton,
} from "src/shared/components/data-grid/components";
import {
  exportAssessmentsStoryCsv,
  exportAssessmentsStoryXlsx,
} from "../../../../entities/hr/export-rates/model";
import {
  Permissions,
  useCheckPermission,
} from "src/shared/hooks/useCheckPermission";

interface SessionsExportTableControlProps {
  selectedIds: string[];
}

const SessionsExportTableControl: React.FC<SessionsExportTableControlProps> = ({
  selectedIds,
}) => {
  const { t } = useTranslation();

  const exportPermission = useCheckPermission(
    Permissions.SessionsResultsExport,
  );

  const handleExportCsv = () => {
    exportAssessmentsStoryCsv({ aId: selectedIds });
  };

  const handleExportXlsx = () => {
    exportAssessmentsStoryXlsx({ aId: selectedIds });
  };

  return (
    <div className="table__control">
      <div className="table__control-btn table__control-btn--hide-on-not-mobile">
        <DataGridMobileSortButton />
      </div>
      <div className="table__control-btn table__control-btn--hide-on-not-mobile">
        <DataGridMobileFiltersButton />
      </div>
      <div className="table__control-btn">
        <BaseButton onClick={handleExportCsv} disabled={!exportPermission}>
          <TextWithIcon
            label={`${t("common.unload")} CSV`}
            iconName={"upload-blue"}
            isTextNowrap
          />
        </BaseButton>
      </div>
      <div className="table__control-btn">
        <BaseButton onClick={handleExportXlsx} disabled={!exportPermission}>
          <TextWithIcon
            label={`${t("common.unload")} XLSX`}
            iconName={"upload-blue"}
            isTextNowrap
          />
        </BaseButton>
      </div>
    </div>
  );
};

export default SessionsExportTableControl;
