import {
  EUserSettingsTablesName,
  TUserSettingsTables,
} from "src/entities/public/user-settings";

export const defaultTablesSettings: TUserSettingsTables = {
  [EUserSettingsTablesName.RATINGS]: {
    columnsVisibility: {
      fullName: true,
      gender: true,
      communication: true,
      analytics: true,
      command: true,
      thinking3d: true,
      economics: true,
      jurisprudence: true,
      created: true,
      lastPlay: true,
      lastActivity: true,
      desiredStudyPlace: true,
      indexNumber: false,
      groupCode: true,
      phone: false,
      email: false,
      birthdate: false,
      communicationTenacity: false,
      analyticsTenacity: false,
      commandTenacity: false,
      country: false,
      region: false,
      district: false,
      school: false,
      locality: false,
      studyWorkPlace: false,
      desiredSpecialty: false,
      statusCompany: false,
      statusEmployment: false,
      rolesInRelatedProjects: false,
      confirmationSingle: false,
      dateConfirmationSingle: false,
      rejectionReason: false,
      acquisitionChannel: false,
      date_of_getting_analytics_lvl_1: false,
      date_of_getting_analytics_lvl_0_5: false,
      additionalCompetencies: false,
      secretCode: false,
    },
  },
  [EUserSettingsTablesName.MAILING]: {
    columnsVisibility: {
      selected: true,
      gPlace: false,
      fullName: true,
      gender: false,
      phone: true,
      email: true,
      birthdate: false,
      gRate: false,
      groupCode: true,
      communication: true,
      communicationTenacity: false,
      analytics: true,
      analyticsTenacity: false,
      command: true,
      commandTenacity: false,
      thinking3d: true,
      thinking3dTenacity: false,
      economics: true,
      economicsTenacity: false,
      jurisprudence: true,
      jurisprudenceTenacity: false,
      created: false,
      lastActivity: true,
      lastPlay: false,
      country: false,
      region: false,
      district: false,
      school: false,
      locality: false,
      studyWorkPlace: false,
      desiredStudyPlace: true,
      desiredSpecialty: false,
      tournaments: false,
    },
  },
};
