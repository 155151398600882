import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import styles from "../header.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { BaseAvatar, EPopupName, TextWithIcon } from "../../../components";
import { LvlState } from "../../../components/lvl-state/lvl-state";
import { useTranslation } from "react-i18next";
import { getCompetenceWithoutTenacity } from "../../../helpers";
import { isMainCompetence } from "../../../helpers/isMainCompetence";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ChevronDown } from "../../../images/icons/chevron-down.svg";
import {
  requestProfileCompetence,
  selectProfileCompetenceItem,
} from "../../../store/ducks/profile-competence";
import { ELanguage } from "../../../store/types";
import i18n from "i18next";
import { BaseSwitcher, SwitcherItem } from "../../base-switcher/base-switcher";
import { openPopup } from "../../base-popup/model";

interface IHeaderDropdown {
  shouldRender: boolean;
  isDropdownOpened: boolean;
  setIsDropdownOpened: React.Dispatch<React.SetStateAction<boolean>>;
  storeKeycloak: Keycloak.KeycloakInstance | null;
  name: string;
  avatarUrl: string | undefined;
  logout: () => void;
  switchLanguage: (value: ELanguage) => void;
  isMobile?: boolean;
  isPlayerClientId: boolean;
}

export const HeaderDropdown: React.FC<IHeaderDropdown> = ({
  shouldRender,
  isDropdownOpened,
  setIsDropdownOpened,
  storeKeycloak,
  name,
  avatarUrl,
  logout,
  switchLanguage,
  isPlayerClientId,
}) => {
  const { t } = useTranslation();
  const competence = useSelector(selectProfileCompetenceItem);
  const dispatch = useDispatch();

  const location = useLocation();

  const filteredCompetence = useMemo(
    () =>
      getCompetenceWithoutTenacity(competence?.competenceRates || []).filter(
        (competenceRate) => isMainCompetence(competenceRate.competence),
      ),
    [competence?.competenceRates],
  );

  const nameHidden = name
    .split(" ")
    .map((field) =>
      !!field.length ? field.slice(0, 1) + "***" + field.slice(-1) : field,
    )
    .join(" ");

  useEffect(() => {
    // todo: hrp-502
    if (location.pathname === "/level-up") return;
    if (!storeKeycloak?.subject) return;
    dispatch(
      requestProfileCompetence({
        id: storeKeycloak?.subject,
      }),
    );
  }, [storeKeycloak?.subject]);

  const switcherItems: SwitcherItem[] = [
    {
      onClick: () => switchLanguage(ELanguage.RU),
      title: "Русский",
      isActive: i18n.language === ELanguage.RU,
    },
    {
      onClick: () => switchLanguage(ELanguage.EN),
      title: "English",
      isActive: i18n.language === ELanguage.EN,
    },
  ];

  return !shouldRender ? null : (
    <div
      className={classNames(styles.header__dropdown, {
        [styles.header__dropdown_is_closed]: !isDropdownOpened,
      })}
    >
      <NavLink
        to="/profile/info"
        aria-label={t("common.goToHomePage")}
        onClick={(e) => {
          e.stopPropagation();
          setIsDropdownOpened((prev) => !prev);
        }}
        className={styles.header__dropdown_profile}
      >
        {!!storeKeycloak?.subject && (
          <BaseAvatar
            customPath={avatarUrl}
            size={60}
            userId={storeKeycloak.subject}
            alt={t("profile.myAvatar")}
            outlineType
          />
        )}
        <div className={styles.header__dropdown_info}>
          <p className={styles.header__dropdown_name}>{nameHidden}</p>
          <div className={styles.header__dropdown_ratings}>
            {filteredCompetence.map((item, index) => (
              <div
                key={index}
                className={styles.header__dropdown_ratings_element}
              >
                <LvlState
                  isDropdown
                  competence={item.competence || ""}
                  diff={item.diff || 0}
                  rate={item.rate || 0}
                />
              </div>
            ))}
          </div>
        </div>
        <ChevronDown className={styles.header__dropdown_chevron} />
      </NavLink>
      <div className={styles.header__dropdown_button_bottom} translate="no">
        <BaseSwitcher items={switcherItems} />
      </div>
      {isPlayerClientId && (
        <div
          className={styles.header__dropdown_button_bottom}
          onClick={() => openPopup({ name: EPopupName.GOOGLE_TRANSLATE })}
        >
          <TextWithIcon
            color="white"
            iconName="g-translate"
            label="Google Translate"
            iconSize={24}
          />
        </div>
      )}
      <div className={styles.header__dropdown_button_bottom} onClick={logout}>
        <TextWithIcon
          iconName="logout-white"
          color="white"
          label={t("menu.btn.logout")}
          iconSize={24}
          labelSize={14}
        />
      </div>
    </div>
  );
};
