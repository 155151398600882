import classNames from "classnames";

import { FC, ReactElement } from "react";

import { useDispatch, useSelector } from "react-redux";

import { BaseButton, TextWithIcon } from "src/shared/components";

import {
  requestGetResume,
  selectCdnGetResumeStatus,
} from "src/shared/store/ducks/cdn/";

import { ERequestStatus } from "src/shared/store/types";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import "./resume-download-block.scss";

interface Props {
  preUploadFile?: string;
  resumeName: string;
  userId?: string;
  isDeleteBtn?: boolean;
  blockLabel?: string;
  className?: string;
  disabled?: boolean;
}

const { openPopup } = popupModel;

export const ResumeDownloadBlock: FC<Props> = ({
  preUploadFile,
  resumeName,
  userId,
  isDeleteBtn = false,
  blockLabel,
  className,
  disabled = false,
}): ReactElement => {
  const dispatch = useDispatch();
  const label: string = blockLabel || resumeName;
  const resumeStatus = useSelector(selectCdnGetResumeStatus);
  const isLoading: boolean = resumeStatus === ERequestStatus.LOADING;

  const getResumeHandler = (): void => {
    if (userId) {
      dispatch(
        requestGetResume({ options: { id: userId }, fileName: resumeName }),
      );
    }
  };

  const openDeleteResumePopup = (): void => {
    openPopup({
      name: EPopupName.DELETE_RESUME,
    });
  };

  const content = (): ReactElement => (
    <span className="resume-download-block__content">
      <span className="resume-download-block__content-label">{label}</span>
    </span>
  );

  return (
    <div className={classNames("resume-download-block", className)}>
      <BaseButton
        lightBlue
        className="resume-download-block__btn"
        isLoading={isLoading}
        onClick={preUploadFile ? undefined : getResumeHandler}
      >
        {preUploadFile ? (
          <a
            className="resume-download-block__link"
            href={preUploadFile}
            download={resumeName}
          >
            {content()}
          </a>
        ) : (
          content()
        )}
      </BaseButton>
      {isDeleteBtn && (
        <BaseButton
          secondary
          className="resume-download-block__delete"
          disabled={isLoading || disabled}
          onClick={openDeleteResumePopup}
        >
          <TextWithIcon iconName="trash-red-filled" hideLabel />
        </BaseButton>
      )}
    </div>
  );
};
