import { FC, ReactElement, useEffect, useMemo, useState } from "react";
import {
  BaseMultiSelect,
  BasePopup,
  EPopupName,
} from "../../../../shared/components";
import { PlayerShortStat } from "../../user-short-stat/player-short-stat";
import { useDispatch } from "react-redux";
import { requestProfileCompetence } from "../../../store/ducks/profile-competence";
import { useUnit } from "effector-react";
import {
  $profileCompetence,
  $rejectionReason,
  $statusCompany,
  $statusEmployment,
  $statusRelatedProjects,
  $userId,
  requestCompetenceFx,
  requestPostRejectionStatusFx,
  requestPostRoleInRelatedProjectFx,
  requestPostStatusCompanyFx,
  requestPostStatusEmploymentFx,
} from "./lib/profile";
import { useTranslation } from "react-i18next";
import useClient from "../../../hooks/useClient";
import { ERequestStatus } from "../../../store/types";
import { formatPhone } from "../../../helpers";
import { format as formatDate } from "date-fns";
import { BaseSelect, SelectItem } from "../../form-fields";
import "./user-profile-popup.scss";
import { BaseLoader } from "../../base-loader/base-loader";
import { BaseButton } from "../../base-button/base-button";
import { closePopup } from "../../../../shared/components/base-popup/model";
import UserComments from "../../user-comments/user-comments";
import { IBaseInputChange, IBaseMultiSelectChange } from "../../../models";
import {
  $companyStatuses,
  $employmentStatuses,
  $rejectionReasons,
  employmentStatusesForEmployeesEnum,
} from "../../../../entities/public/rating-statuses/model";
import { TextWithIcon } from "../../text-with-icon/text-with-icon";
import { stringsToEnumArray, stringToEnum } from "../../../helpers/enums";
import {
  RejectionReasonEnum,
  RoleInRelatedProjectEnum,
  StatusCompanyEnum,
  StatusEmploymentEnum,
} from "../../../../generated/social";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { $keycloak } from "../../../../entities/public/keycloak/model";
import {
  $subjectInfoRequestStatus,
  $subjectUser,
  cleanUserInfoAdmin,
  requestGetUserInfoAdmin,
} from "../../../../pages/users-control/user-info-page/lib/user-info-page";
import {
  Permissions,
  useCheckPermission,
} from "src/shared/hooks/useCheckPermission";

interface IInfoList {
  title: string;
  description?: string | ReactElement;
}

interface IProps {
  onClose?: () => void;
}
const UserProfilePopup: FC<IProps> = ({ onClose }) => {
  const [, setFirstTry] = useState<boolean>(true);

  const keycloak = useUnit($keycloak);

  const { t } = useTranslation();

  const personalInfoPermission = useCheckPermission(
    Permissions.RatingsPersonalInfo,
  );

  const dispatch = useDispatch();
  const profileInfo = useUnit($subjectUser);
  const profileInfoStatus = useUnit($subjectInfoRequestStatus);
  const { isPlayerClientId, isHrClientId, isAdminClientId } = useClient();

  const isHrOrAdminClient = isHrClientId || isAdminClientId;

  const { isMobile } = useMediaQuery();

  const [formattedPhone, setFormattedPhone] = useState<string>();

  const userId = useUnit($userId);

  const profileCompetence = useUnit($profileCompetence);

  const statusCompany = useUnit($statusCompany);

  const statusEmployment = useUnit($statusEmployment);

  const statusRoleDesiredProject = useUnit($statusRelatedProjects);

  const rejectionReason = useUnit($rejectionReason);

  const companyStatusesList = useUnit($companyStatuses);

  const employmentStatusesListForEmployee = {
    statusesEmployment: [Object.values(employmentStatusesForEmployeesEnum)][0],
  };

  const employmentStatusesListForEnrollee = useUnit($employmentStatuses);

  const employmentStatusesList =
    statusCompany.status !== StatusCompanyEnum.Enrollee
      ? employmentStatusesListForEmployee
      : employmentStatusesListForEnrollee;

  const rejectionReasonsList = useUnit($rejectionReasons);

  const excludeStatus = [
    StatusCompanyEnum.Student,
    StatusCompanyEnum.Relative,
    StatusCompanyEnum.Employee,
  ];

  const companyStatusSelectItems: SelectItem[] =
    companyStatusesList.statusesCompany
      .filter((status) => !excludeStatus.includes(status))
      .map((status) => ({
        value: String(status),
        label: t(`table.ratings.statusCompany.${status}`),
      }));

  const employmentStatusSelectItems =
    employmentStatusesList.statusesEmployment.map((status) => ({
      value: String(status),
      label: t(`table.ratings.statusEmployment.${status}`),
    }));

  const roleInRelatedProjectsItems: SelectItem[] = useMemo(
    () =>
      Object.values(RoleInRelatedProjectEnum).map((role) => ({
        label: t(`table.users.rolesInRelatedProjects.${role}`),
        value: role,
      })),
    [t],
  );

  const rejectionReasonSelectItems: SelectItem[] =
    rejectionReasonsList.rejectionReasons.map((status) => ({
      value: String(status),
      label: t(`table.ratings.rejectionReason.${status}`),
    }));

  const handleCompanyStatusChange = (event: IBaseInputChange) => {
    if (profileCompetence.player) {
      requestPostStatusCompanyFx({
        userId: profileCompetence.player?.id,
        status: stringToEnum(StatusCompanyEnum, event.value),
      });
    }
  };

  const handleRoleInRelatedProjectChange = (event: IBaseMultiSelectChange) => {
    if (profileCompetence.player) {
      requestPostRoleInRelatedProjectFx({
        userId: profileCompetence.player?.id,
        status: stringsToEnumArray(RoleInRelatedProjectEnum, event.value),
      });
    }
  };

  const handleEmploymentStatusChange = (event: IBaseInputChange) => {
    if (profileCompetence.player) {
      requestPostStatusEmploymentFx({
        userId: profileCompetence.player?.id,
        status: stringToEnum(StatusEmploymentEnum, event.value),
      });
    }
  };

  const handleRejectionReasonChange = (event: IBaseInputChange) => {
    if (profileCompetence.player) {
      requestPostRejectionStatusFx({
        userId: profileCompetence.player?.id,
        status: stringToEnum(RejectionReasonEnum, event.value),
      });
    }
  };

  useEffect(() => {
    const competenceRequest = (userId: string): void => {
      dispatch(
        requestProfileCompetence({
          id: userId,
          callback: () => setFirstTry(false),
        }),
      );
    };

    if (userId) {
      requestCompetenceFx(userId);
      requestGetUserInfoAdmin({ id: userId });
    } else if (!userId && keycloak?.subject) {
      competenceRequest(keycloak.subject);
    }
  }, [userId, dispatch, keycloak?.subject]);

  useEffect(() => {
    return () => {
      if (isHrOrAdminClient) {
        cleanUserInfoAdmin();
        // dispatch(cleanProfileCompetence());
      }
    };
  }, [dispatch, isHrOrAdminClient, isPlayerClientId]);

  useEffect(() => {
    const phone: string | undefined = profileInfo?.attributes?.phoneNumber?.[0];

    formatPhone(phone).then((newPhone) => {
      setFormattedPhone(newPhone);
    });
  }, [profileInfo?.attributes?.phoneNumber]);

  const userPhoneNumber = useMemo<ReactElement | undefined>(() => {
    if (formattedPhone) {
      const phone = profileInfo?.attributes?.phoneNumber?.[0];

      return (
        <a href={`tel:${phone}`} className="rating-page__list-item-link">
          {formattedPhone}
        </a>
      );
    }
  }, [formattedPhone, profileInfo?.attributes?.phoneNumber]);

  const userEmail = useMemo<ReactElement | undefined>(() => {
    if (profileInfo?.email) {
      return (
        <a
          href={`mailto:${profileInfo.email}`}
          className="rating-page__list-item-link"
        >
          {profileInfo.email}
        </a>
      );
    }
  }, [profileInfo?.email]);

  const userBirthdate = useMemo<string | undefined>(() => {
    if (profileInfo?.attributes?.birthdate?.[0]) {
      return formatDate(
        new Date(profileInfo.attributes.birthdate[0]),
        "dd.MM.yyyy",
      );
    }
  }, [profileInfo?.attributes?.birthdate]);

  const desiredStudyPlace = useMemo<string | undefined>(() => {
    if (profileInfo?.attributes?.desiredStudyPlace?.[0]) {
      return t(
        `userForm.select.desiredStudyPlace.${profileInfo.attributes?.desiredStudyPlace[0]}`,
      );
    }
  }, [profileInfo?.attributes?.desiredStudyPlace, t]);

  const desiredSpecialty =
    profileInfo?.attributes?.desiredStudyPlace?.[0] &&
    !!profileInfo?.attributes?.desiredSpecialty?.length
      ? profileInfo?.attributes?.desiredSpecialty
          .map((item) =>
            t(
              `userForm.multiSelect.${profileInfo?.attributes?.desiredStudyPlace?.[0]}.${item}`,
            ),
          )
          .join(", ")
      : undefined;

  const infoList: IInfoList[] = [];

  if (isHrOrAdminClient && profileInfo) {
    if (userPhoneNumber && personalInfoPermission) {
      infoList.push({
        title: t("userForm.inputLabel.phoneNumber"),
        description: userPhoneNumber,
      });
    }

    if (userEmail && personalInfoPermission) {
      infoList.push({
        title: t("userForm.inputLabel.email"),
        description: userEmail,
      });
    }

    if (userBirthdate && personalInfoPermission) {
      infoList.push({
        title: t("userForm.inputLabel.birthdate"),
        description: userBirthdate,
      });
    }

    if (profileInfo?.attributes?.locality?.[0]) {
      infoList.push({
        title: t("userForm.inputLabel.locality"),
        description: profileInfo?.attributes?.locality?.[0],
      });
    }

    if (profileInfo?.attributes?.studyWorkPlace?.[0]) {
      infoList.push({
        title: t("userForm.inputLabel.studyWorkPlace"),
        description: profileInfo?.attributes?.studyWorkPlace?.[0],
      });
    }

    if (desiredStudyPlace) {
      infoList.push({
        title: t("userForm.inputLabel.desiredStudyPlace"),
        description: desiredStudyPlace,
      });
    }

    if (desiredSpecialty) {
      infoList.push({
        title: t("userForm.inputLabel.desiredSpecialty"),
        description: desiredSpecialty,
      });
    }
  }

  const closeProfilePopup = () => {
    onClose && onClose();
    closePopup({
      name: EPopupName.USER_PROFILE_POPUP,
    });
  };

  return (
    <BasePopup
      name={EPopupName.USER_PROFILE_POPUP}
      className="user-profile-popup"
      onClose={() => onClose && onClose()}
    >
      {profileCompetence.status === ERequestStatus.LOADING ||
      profileInfoStatus === ERequestStatus.LOADING ? (
        <BaseLoader />
      ) : (
        <>
          <div className="user-profile__header">
            <BaseButton
              lightPurple
              submit={false}
              className="user-profile__sessions"
              to={`/ratings/rating/${userId}/sessions-history`}
            >
              <TextWithIcon
                iconName="history-blue"
                iconSize={22}
                label={t("sessionsHistory.title")}
              />
            </BaseButton>
            <BaseButton
              lightPurple
              submit={false}
              className="user-profile__sessions"
              to={`/ratings/rating/${userId}/tutorial-history`}
            >
              <TextWithIcon
                iconName="history-blue"
                iconSize={22}
                label={t("tutorialHistory.title")}
              />
            </BaseButton>
            <BaseButton
              lightPurple
              submit={false}
              className="user-profile__sessions"
              to={`/ratings/rating/${userId}/fines-history`}
            >
              <TextWithIcon
                iconName="history-blue"
                iconSize={22}
                label={t("fineHistory.title")}
              />
            </BaseButton>
            <BaseButton
              lightPurple
              submit={false}
              className="user-profile__sessions"
              to={`/ratings/rating/${userId}/complaints-history`}
            >
              <TextWithIcon
                iconName="history-blue"
                iconSize={22}
                label={t("complaintsHistory.title")}
              />
            </BaseButton>
            {!isMobile && (
              <BaseButton transparent onClick={closeProfilePopup}>
                <TextWithIcon iconName="cross-blue" iconSize={20} hideLabel />
              </BaseButton>
            )}
          </div>

          {profileInfo && profileCompetence.player && (
            <>
              <PlayerShortStat
                profileCompetence={profileCompetence.player}
                profileInfo={profileInfo}
              />
            </>
          )}
          {infoList.length && (
            <ul className="rating-page__list">
              {infoList.map((item) => (
                <li key={item.title} className="rating-page__list-item">
                  <div className="rating-page__list-item-title">
                    {item.title}
                  </div>
                  <div className="rating-page__list-item-description">
                    {item.description}
                  </div>
                </li>
              ))}
            </ul>
          )}
          {!profileInfo?.emailVerified ? null : (
            <div className="user-profile__status-block">
              <BaseSelect
                placeholder={t("profile.info.statusInCompany")}
                activeItem={statusCompany.status}
                items={companyStatusSelectItems}
                hideEmptyItem
                menuPlacement="bottom"
                onChange={handleCompanyStatusChange}
                className="user-profile__status-block__select"
                disabled={statusCompany.isLoading}
                isStatusCompany
              />
              <BaseSelect
                placeholder={t("profile.info.employmentStatus")}
                activeItem={statusEmployment.status}
                items={employmentStatusSelectItems}
                hideEmptyItem
                menuPlacement="bottom"
                onChange={handleEmploymentStatusChange}
                className="user-profile__status-block__select"
                disabled={statusEmployment.isLoading}
              />

              {statusEmployment.status === StatusEmploymentEnum.Rejection &&
                statusCompany.status === StatusCompanyEnum.Enrollee && (
                  <BaseSelect
                    placeholder={t("profile.info.rejectionReason")}
                    activeItem={rejectionReason.status}
                    items={rejectionReasonSelectItems}
                    hideEmptyItem
                    menuPlacement="bottom"
                    onChange={handleRejectionReasonChange}
                    className="user-profile__status-block__select"
                    disabled={rejectionReason.isLoading}
                  />
                )}

              <BaseMultiSelect
                placeholder={t("table.header.rolesInRelatedProjects")}
                activeItems={statusRoleDesiredProject.status}
                items={roleInRelatedProjectsItems}
                onChange={handleRoleInRelatedProjectChange}
                className="user-profile__status-block__select"
                disabled={statusRoleDesiredProject.isLoading}
              />
            </div>
          )}

          {profileCompetence.player && (
            <UserComments user={profileCompetence.player} />
          )}
        </>
      )}
    </BasePopup>
  );
};

export default UserProfilePopup;
