import {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  AvatarCropPopup,
  BaseButton,
  BaseError,
  ConfirmPopup,
  TextWithIcon,
} from "..";
import "./avatar-upload.scss";
import emptyAvatar from "src/shared/images/avatar-empty.svg";
import classNames from "classnames";
import { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { EPopupName, popupModel } from "src/shared/components/base-popup";
import { useUnit } from "effector-react";

interface Props {
  avatarUrl: string;
  title?: string;
  onChange: (file: File | undefined, avatar: string) => void;
  error?: string;
  isCircleCrop?: boolean;
  cropRatio?: number;
  fileSize: number;
  formats: string[];
  descriptions?: string[];
  cropDescriptions?: string[];
  cropTitle: string;
  className?: string;
  disabled?: boolean;
}

const checkFileSize = (file: File, maxSize: number): boolean => {
  return maxSize > file?.size;
};

const { $activePopups, openPopup, closePopup } = popupModel;

export const AvatarUpload: FC<Props> = ({
  avatarUrl,
  onChange,
  error,
  isCircleCrop = true,
  cropRatio = 1,
  formats,
  fileSize,
  cropDescriptions,
  cropTitle,
  className,
  disabled = false,
}): ReactElement => {
  const { t } = useTranslation();
  const activePopups = useUnit($activePopups);
  const localAvatarUrl: string = avatarUrl || emptyAvatar;
  const imgRef = useRef<HTMLImageElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFileFormat, setSelectedFileFormat] = useState<string>("");
  const [upImg, setUpImg] = useState<string>("");
  const [completedCrop, setCompletedCrop] = useState<Partial<Crop> | null>(
    null,
  );
  const [crop, setCrop] = useState<Partial<Crop>>({});
  const isCropPopup: boolean = useMemo(
    () => activePopups.some(({ name }) => name === EPopupName.AVATAR_CROP),
    [activePopups],
  );
  const inputAcceptFormats: string = formats.join(", ");
  const localFormats = formats
    .map((item) => item.replace("image/", ""))
    .join(", ");
  Number((fileSize / (1024 * 1024)).toFixed(2));

  const onCloseCropPopup = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setUpImg("");
  };

  useEffect(() => {
    if (upImg) {
      openPopup({ name: EPopupName.AVATAR_CROP });
    }
  }, [upImg]);

  const onSaveCrop = () => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const canvas = document.createElement("canvas");
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    const cropWidth: number = completedCrop.width || 0;
    const cropHeight: number = completedCrop.height || 0;
    const cropX: number = completedCrop.x || 0;
    const cropY: number = completedCrop.y || 0;

    if (ctx) {
      canvas.width = cropWidth * pixelRatio * scaleX;
      canvas.height = cropHeight * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
        image,
        cropX * scaleX,
        cropY * scaleY,
        cropWidth * scaleX,
        cropHeight * scaleY,
        0,
        0,
        cropWidth * scaleX,
        cropHeight * scaleY,
      );

      const reader = new FileReader();

      const currFileFormat: string = formats.includes(selectedFileFormat)
        ? selectedFileFormat
        : "image/jpeg";

      canvas.toBlob(
        (blob) => {
          if (!blob) {
            openPopup({
              name: EPopupName.BASE_MESSAGE_POPUP,
              message: {
                text: t("profile.info.avatar.too_small"),
                isError: true,
              },
            });
            return;
          }

          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              const file = new File([blob], "cropped", {
                type: currFileFormat,
              });

              const validateSize: boolean = checkFileSize(file, fileSize);

              if (!validateSize) {
                onShowPopupHandler(t("common.errorMessage.fileSize"));

                return;
              }

              onChange(file, reader.result);

              closePopup({
                name: EPopupName.AVATAR_CROP,
                callback: onCloseCropPopup,
              });
            }
          };
        },
        currFileFormat,
        0.3,
      );
    }
  };

  const onChangeAvatarHandler = (e: ChangeEvent) => {
    const file: File | undefined = (e.target as HTMLInputElement).files?.[0];

    if (file) {
      const isCorrectFormat: boolean = formats.includes(file.type);

      if (!isCorrectFormat) {
        const errorMessage: string = `${t(
          "popup.uploadPopup.formatError",
        )} ${localFormats}.`;

        onShowPopupHandler(errorMessage);

        if (fileInputRef?.current) {
          fileInputRef.current.value = "";
        }

        return;
      }

      const validateSize: boolean = checkFileSize(file, fileSize);

      if (!validateSize) {
        onShowPopupHandler(t("common.errorMessage.fileSize"));

        return;
      }

      const reader: FileReader = new FileReader();

      const errorLoad = () => {
        console.error(`File reader error: ${file.name}`);

        onShowPopupHandler(t("common.errorMessage.file.notUpload"));
      };

      const successLoad = () => {
        if (typeof reader.result === "string") {
          setSelectedFileFormat(file.type);

          setUpImg(reader.result);
        }
      };

      reader.addEventListener("error", errorLoad);

      reader.addEventListener("load", successLoad);

      reader.readAsDataURL(file);
    }
  };

  const onShowPopupHandler = (text: string) => {
    openPopup({
      name: EPopupName.BASE_MESSAGE_POPUP,
      message: {
        text,
        isError: true,
      },
    });
  };

  const onDeleteAvatarHandler = () => {
    onChange(undefined, "");

    closePopup({ name: EPopupName.DELETE_AVATAR });
  };

  const onChangeCrop = (localCrop: Crop): void => {
    setCrop(localCrop);
  };
  const onCompleteCrop = (localCrop: Crop): void => {
    setCompletedCrop(localCrop);
  };

  const onImageLoaded = useCallback(
    (img: HTMLImageElement) => {
      imgRef.current = img;

      // Отцентровка кроппера
      const aspect = cropRatio;
      const width =
        img.width / aspect < img.height * aspect
          ? 80
          : ((img.height * aspect) / img.width) * 80;
      const height =
        img.width / aspect > img.height * aspect
          ? 80
          : (img.width / aspect / img.height) * 80;
      const y = (100 - height) / 2;
      const x = (100 - width) / 2;

      const localCrop: Partial<Crop> = {
        unit: "px",
        width: (width / 100) * img.width,
        height: (height / 100) * img.height,
        x: (x / 100) * img.width,
        y: (y / 100) * img.height,
        aspect,
      };

      setCrop(localCrop);
      setCompletedCrop(localCrop);

      return false;
    },
    [cropRatio],
  );

  return (
    <div className={classNames("avatar-upload", className)}>
      <div className="avatar-upload__container">
        <div
          className={classNames("avatar-upload__container-upload", {
            "avatar-upload__container-upload--no-photo": !avatarUrl,
          })}
        >
          <div className="avatar-upload__btn-wrap">
            {avatarUrl ? (
              <div className="avatar-upload__btn">
                <BaseButton
                  outline
                  className="avatar-upload__btn-circle"
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                  disabled={disabled}
                >
                  <TextWithIcon
                    iconName="camera-blue-filled"
                    hideLabel
                    iconSize={20}
                  />
                </BaseButton>
                {t("common.edit")}
              </div>
            ) : (
              <div className="avatar-upload__btn--no-photo">
                <BaseButton
                  lightBlue
                  className="avatar-upload__btn-upload"
                  onClick={() => {
                    fileInputRef.current?.click();
                  }}
                  disabled={disabled}
                >
                  <TextWithIcon
                    iconName="camera-blue-filled"
                    iconSize={20}
                    label={t("profile.info.avatar.upload")}
                  />
                </BaseButton>
              </div>
            )}
            <input
              className={classNames("avatar-upload__btn-input", {
                "error-field": !!error,
              })}
              ref={fileInputRef}
              type="file"
              accept={inputAcceptFormats}
              onChange={onChangeAvatarHandler}
              hidden
            />
          </div>
          <div className="avatar-upload__img-wrap">
            <img
              src={localAvatarUrl}
              alt="avatar"
              className="avatar-upload__img"
            />
          </div>
          {avatarUrl && (
            <div className="avatar-upload__delete-btn">
              <BaseButton
                outline
                secondary
                className="avatar-upload__delete-btn-circle"
                onClick={() => openPopup({ name: EPopupName.DELETE_AVATAR })}
                disabled={disabled}
              >
                <TextWithIcon
                  iconName="trash-red-filled"
                  hideLabel
                  iconSize={20}
                />
              </BaseButton>
              {t("common.delete")}
            </div>
          )}
        </div>
      </div>
      {!!error && (
        <BaseError
          text={error}
          type="danger"
          className="avatar-upload__error"
        />
      )}
      <ConfirmPopup
        popupName={EPopupName.DELETE_AVATAR}
        reverse
        title={t("profile.popup.deleteAvatar.title")}
        description={t("profile.popup.deleteAvatar.description")}
        confirmBtnLabel={t("profile.info.document.btn.delete")}
        onConfirm={onDeleteAvatarHandler}
      />
      {isCropPopup && (
        <AvatarCropPopup
          isCircleCrop={isCircleCrop}
          crop={crop}
          imgSrc={upImg}
          onImageLoaded={onImageLoaded}
          onChangeCrop={onChangeCrop}
          onCompleteCrop={onCompleteCrop}
          onSaveCrop={onSaveCrop}
          onClosePopup={onCloseCropPopup}
          descriptions={cropDescriptions}
          title={cropTitle}
        />
      )}
    </div>
  );
};
