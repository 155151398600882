import { FC, ReactElement, useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";

import { useUnit } from "effector-react";

import { Competence, PlayerDto } from "src/generated/statistic";

import { GameType } from "src/generated/game";

import {
  BaseButton,
  BaseLoader,
  EmptyBlock,
  EPopupDataIdName,
  EPopupName,
  ITeamGridItem,
  PopupGridGroup,
  popupModel,
  TextWithIcon,
} from "src/shared/components";

import {
  getCoefficientText,
  getGridNumberColor,
  getSimulationRole,
  getTableItems,
  splitStrings,
} from "src/shared/helpers";

import { TableItem } from "src/shared/models/table";

import { IBaseInputChange } from "src/shared/models/base-input";

import {
  EDataGridColumnType,
  EDataGridTextColor,
  EDataGridTextWeight,
  IDataGridColumn,
  IDataGridRow,
} from "src/shared/models/dataGrid";

import {
  $assessmentResults,
  cleanAssessmentResults,
  requestAssessmentResults,
} from "src/entities/public/assessments-results";

import { ERequestStatus, IGameType, Specialties } from "src/shared/store/types";

import { keycloakModel } from "src/entities/public/keycloak";

import { dislikeTooltipFactory } from "src/features/public/dislike-info";

import "./assessment-results-popup.scss";
import { ICorpGridItem } from "../../popup-grid-group/corp-results-grid/corp-results-grid";
import {
  exportAssessmentHistoryCsv,
  exportAssessmentHistoryXlsx,
} from "../../../../entities/hr/export-rates/model";
import { getCompetencies } from "../../../helpers/getCommonCompetency";
import { getGridCommonColor } from "../../../helpers/data-grids/getGridCommonColor";
import { postExportSessionsHistory } from "../../../../entities/admin/histories/model/sessions/sessions-export-history";
import {
  Permissions,
  useCheckPermission,
} from "src/shared/hooks/useCheckPermission";

interface ITeamsItem {
  teamNum: number;
  place: number;
}

interface ICorpTeamsItem {
  areaNum: number;
  corpNum: number;
  place: number;
}

interface Props {}

const { $isAdminClientId } = keycloakModel;

const { openPopup, $activePopups } = popupModel;

export const AssessmentResultsPopup: FC<Props> = (): ReactElement => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { item: results, statusItem: resultsLoadingStatus } =
    useUnit($assessmentResults);

  const activePopups = useUnit($activePopups);

  const itemId = useMemo(() => {
    const currentPopup = activePopups.find(
      ({ name }) => name === EPopupName.ASSESSMENT_RESULT,
    );

    const currentPopupDataId = currentPopup?.dataId?.find(
      ({ name }) => name === EPopupDataIdName.DEFAULT,
    )?.value;

    return currentPopupDataId || "";
  }, [activePopups]);

  const isAdminClientId = useUnit($isAdminClientId);

  const [tableResults, setTableResults] = useState<TableItem[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");

  const isTeamGrid: boolean = results?.gType === GameType.Team;

  const isCorpGrid: boolean = results?.gType === GameType.Corp;

  const isSoloGrid: boolean =
    results?.gType === GameType.Tutorial ||
    results?.gType === GameType.Single ||
    results?.gType === GameType.Solo;

  const isLoading: boolean = resultsLoadingStatus === ERequestStatus.LOADING;

  const isLoaded: boolean = resultsLoadingStatus === ERequestStatus.LOADED;

  const isError: boolean = resultsLoadingStatus === ERequestStatus.ERROR;

  const isEmptyResultsReady: boolean = !tableResults.length && isLoaded;

  const gameType: IGameType | undefined =
    results?.rawMetaJson.gameTypes[results?.gType];

  const exportPermission = useCheckPermission(
    Permissions.SessionsResultsExport,
  );

  useEffect(() => {
    if (itemId) {
      requestAssessmentResults({ id: itemId });
    }
  }, [itemId]);

  useEffect(() => {
    if (results) {
      setTableResults(() => {
        if (results.players) {
          return results.players.map((item: PlayerDto) => {
            return getTableItems(item);
          });
        }

        return [];
      });
    }
  }, [dispatch, results]);

  const onChangeSearch = ({ value }: IBaseInputChange) => {
    setSearchValue(value);
  };

  const soloColumns = useMemo<IDataGridColumn[]>(() => {
    if (isSoloGrid) {
      return [
        {
          title: t("table.header.member.name"),
          type: EDataGridColumnType.AVATAR,
          key: "fullName",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
        },
        {
          title: t("table.header.position"),
          type: EDataGridColumnType.STRING,
          key: "place",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
        },
        {
          title: t("table.header.rating"),
          type: EDataGridColumnType.STRING,
          key: "rating",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
        },
        {
          title: t("table.header.communication.short"),
          type: EDataGridColumnType.STRING,
          key: "communication",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
        },
        {
          title: t("table.header.analytics.short"),
          type: EDataGridColumnType.STRING,
          key: "analytics",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
        },
        {
          title: t("table.header.command.short"),
          type: EDataGridColumnType.STRING,
          key: "command",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
        },
        {
          title: t("table.header.competencies.name"),
          type: EDataGridColumnType.STRING,
          key: "commonCompetencies",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
          minWidth: 60,
          noPaddingLeft: true,
        },
        {
          title: t("table.header.role"),
          type: EDataGridColumnType.STRING,
          key: "role",
          showed: true,
          titleUppercase: true,
          fontWeight: EDataGridTextWeight.BOLD,
          textAlign: "right",
        },
        {
          title: "",
          hiddenTitle: true,
          type: EDataGridColumnType.BUTTON,
          key: "settingsButton",
          showed: true,
          width: 16,
          buttonProps: {
            hideLabel: true,
            icon: "settings-gray",
            iconSize: 22,
            onClick: (id: string) => {
              openPopup({
                name: EPopupName.ASSESSMENT_ADDITIONAL_PARAMETERS,
                dataId: [{ name: EPopupDataIdName.DEFAULT, value: id }],
              });
            },
          },
        },
      ];
    }

    return [];
  }, [t, isSoloGrid]);

  const soloRows = useMemo<IDataGridRow[]>(() => {
    if (results?.players && isSoloGrid) {
      return results.players.reduce(
        (final: IDataGridRow[], curr: PlayerDto) => {
          const {
            commandDiff,
            communicationDiff,
            analyticsDiff,
            jurisprudenceDiff,
            thinking3dDiff,
            role,
            lastname,
            firstname,
            pId,
            place,
          } = curr;

          const commonCompetencies: Record<string, number>[] = [
            {
              [Competence.Thinking3d]: thinking3dDiff,
            },
            {
              [Competence.Jurisprudence]: jurisprudenceDiff,
            },
          ];

          const userLink: string = isAdminClientId
            ? `/users-control/user/${pId}/info`
            : `/ratings/rating/${pId}`;

          const isBot: boolean = firstname === "Bot" && lastname === "Bot";

          const rating: number =
            Math.round(
              (communicationDiff +
                commandDiff +
                analyticsDiff +
                jurisprudenceDiff +
                thinking3dDiff) *
                100,
            ) / 100;
          const isNotEmptyResults: boolean =
            !!curr.results && !!Object.keys(curr.results).length;

          const getRole = () => {
            if (role) {
              const gameTypeKey: GameType = results.gType;

              const gameTypeInfo = results.rawMetaJson.gameTypes[gameTypeKey];

              return getSimulationRole({
                roleKey: role,
                gameType: gameTypeInfo,
                lang: i18n.language,
              }).role;
            }

            return "";
          };

          const fullName: string = splitStrings([firstname, lastname]);

          const isNotMatch: boolean =
            !!searchValue &&
            !fullName
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase());

          if (isNotMatch) {
            return final;
          }

          final.push({
            id: pId,
            selected: false,
            fullName,
            rating: getCoefficientText(rating),
            communication: getCoefficientText(communicationDiff),
            command: getCoefficientText(commandDiff),
            analytics: getCoefficientText(analyticsDiff),
            commonCompetencies: getCompetencies({}, commonCompetencies),
            place: place,
            role: getRole(),
            avatarProps: !isBot
              ? {
                  alt: fullName,
                  userId: pId,
                  link: userLink,
                }
              : undefined,
            columnsTextColor: {
              place: !place ? EDataGridTextColor.GRAY : null,
              rating: getGridNumberColor(rating),
              communication: getGridNumberColor(communicationDiff),
              command: getGridNumberColor(commandDiff),
              analytics: getGridNumberColor(analyticsDiff),
              commonCompetencies: getGridCommonColor(commonCompetencies),
            },
            buttonProps: {
              settingsButton: {
                disabled: !isNotEmptyResults,
              },
            },
          });

          return final;
        },
        [],
      );
    }

    return [];
  }, [results, i18n.language, searchValue, isSoloGrid, isAdminClientId]);

  const teamColumns = useMemo<IDataGridColumn[]>(
    () => [
      {
        title: t("table.header.member.name"),
        hiddenTitle: true,
        type: EDataGridColumnType.AVATAR,
        key: "fullName",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        minWidth: 340,
        width: 340,
      },
      {
        title: t("table.header.position"),
        type: EDataGridColumnType.STRING,
        key: "place",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 100,
        minWidth: 100,
        noPaddingLeft: true,
      },
      {
        title: t("table.header.role"),
        type: EDataGridColumnType.STRING,
        key: "role",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 160,
        minWidth: 160,
        noPaddingLeft: true,
      },
      {
        title: t("table.header.communication.short"),
        type: EDataGridColumnType.STRING,
        key: "communication",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 60,
        minWidth: 60,
        noPaddingLeft: true,
        noPaddingRight: true,
      },
      {
        title: "",
        type: EDataGridColumnType.STRING,
        key: "communicationDislike",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 80,
        minWidth: 80,
        noPaddingLeft: true,
      },
      {
        title: t("table.header.analytics.short"),
        type: EDataGridColumnType.STRING,
        key: "analytics",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 60,
        minWidth: 60,
        noPaddingLeft: true,
        noPaddingRight: true,
      },
      {
        title: "",
        type: EDataGridColumnType.STRING,
        key: "analyticsDislike",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 80,
        minWidth: 80,
        noPaddingLeft: true,
      },
      {
        title: t("table.header.command.short"),
        type: EDataGridColumnType.STRING,
        key: "command",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 60,
        minWidth: 60,
        noPaddingLeft: true,
        noPaddingRight: true,
      },
      {
        title: "",
        type: EDataGridColumnType.STRING,
        key: "commandDislike",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 80,
        minWidth: 80,
        noPaddingLeft: true,
      },
      {
        title: t("table.header.competencies.name"),
        type: EDataGridColumnType.STRING,
        key: "commonCompetencies",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        minWidth: 60,
        noPaddingRight: true,
      },
      {
        title: t(""),
        type: EDataGridColumnType.STRING,
        key: "commonCompetenciesDislikes",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        minWidth: 120,
      },
      {
        title: t("table.header.teamRole"),
        type: EDataGridColumnType.STRING,
        key: "teamRole",
        showed: true,
        titleUppercase: true,
        fontWeight: EDataGridTextWeight.BOLD,
        width: 155,
        minWidth: 155,
        noPaddingLeft: true,
        textAlign: "right",
      },
      {
        title: "",
        hiddenTitle: true,
        type: EDataGridColumnType.BUTTON,
        key: "settingsButton",
        showed: true,
        width: 55,
        minWidth: 55,
        noPaddingLeft: true,
        buttonProps: {
          hideLabel: true,
          icon: "settings-gray",
          iconSize: 22,
          onClick: (id: string) => {
            openPopup({
              name: EPopupName.ASSESSMENT_ADDITIONAL_PARAMETERS,
              dataId: [{ name: EPopupDataIdName.DEFAULT, value: id }],
            });
          },
        },
      },
    ],
    [t],
  );

  const teamRows: ITeamGridItem[] = useMemo(() => {
    if (results?.players && isTeamGrid) {
      const tmpTeamsNums = results.players.reduce(
        (final: ITeamsItem[], { teamNum, place }: PlayerDto) => {
          const someEqual: boolean = final.some(
            (item) => item.place === place && item.teamNum === teamNum,
          );

          if (!someEqual && teamNum !== undefined && place !== undefined) {
            final.push({
              teamNum,
              place,
            });
          }

          return final;
        },
        [],
      );

      const teamsNums = tmpTeamsNums.sort((a, b) => a.place - b.place);

      return teamsNums.reduce(
        (final: ITeamGridItem[], { place, teamNum }: ITeamsItem) => {
          const players: PlayerDto[] | undefined = results.players?.filter(
            (item) => item.teamNum === teamNum && item.place === place,
          );

          if (!!players?.length && gameType) {
            const rows = players.reduce(
              (final: IDataGridRow[], curr: PlayerDto) => {
                const {
                  communicationTenacityDiff,
                  economicsTenacityDiff,
                  commandDiff,
                  communicationDiff,
                  commandTenacityDiff,
                  analyticsDiff,
                  analyticsTenacityDiff,
                  jurisprudenceDiff,
                  jurisprudenceTenacityDiff,
                  thinking3dDiff,
                  thinking3dTenacityDiff,
                  role,
                  lastname,
                  firstname,
                  pId,
                  teamName,
                  dislikes,
                } = curr;

                const userLink: string = isAdminClientId
                  ? `/users-control/user/${pId}/info`
                  : `/ratings/rating/${pId}`;

                const isBot: boolean =
                  firstname === "Bot" && lastname === "Bot";

                const fullName: string = splitStrings([firstname, lastname]);

                const notFound: boolean = !fullName.includes(searchValue);

                const isNotEmptyResults: boolean =
                  !!curr.results && !!Object.keys(curr.results).length;

                const getRole = () => {
                  if (role) {
                    const gameTypeKey: GameType = GameType.Team;
                    const gameTypeInfo =
                      results.rawMetaJson.gameTypes[gameTypeKey];

                    return getSimulationRole({
                      roleKey: teamName || "",
                      gameType: gameTypeInfo,
                      lang: i18n.language,
                      teamRoleKey: role,
                    });
                  }
                };

                const dislikesTooltips = dislikeTooltipFactory(
                  dislikes,
                  gameType,
                  [
                    Competence.Communication,
                    Competence.Analytics,
                    Competence.Command,
                    Competence.Jurisprudence,
                    Competence.Thinking3d,
                    Competence.AnalyticsTenacity,
                    Competence.CommunicationTenacity,
                    Competence.CommandTenacity,
                    Competence.EconomicsTenacity,
                    Competence.JurisprudenceTenacity,
                    Competence.Thinking3dTenacity,
                  ],
                );

                const commonCompetencies: Record<string, number>[] = [
                  {
                    [Competence.Thinking3d]: thinking3dDiff,
                  },
                  {
                    [Competence.Jurisprudence]: jurisprudenceDiff,
                  },
                  {
                    [Competence.AnalyticsTenacity]: analyticsTenacityDiff,
                  },
                  {
                    [Competence.CommunicationTenacity]:
                      communicationTenacityDiff,
                  },
                  {
                    [Competence.CommandTenacity]: commandTenacityDiff,
                  },
                  {
                    [Competence.EconomicsTenacity]: economicsTenacityDiff ?? 0,
                  },
                  {
                    [Competence.JurisprudenceTenacity]:
                      jurisprudenceTenacityDiff ?? 0,
                  },
                  {
                    [Competence.Thinking3dTenacity]: thinking3dTenacityDiff,
                  },
                ];

                final.push({
                  id: pId,
                  selected: false,
                  isTransparent: notFound,
                  fullName,
                  place: " ",
                  role: " ",
                  communication: getCoefficientText(communicationDiff),
                  communicationDislike: getCoefficientText(
                    dislikesTooltips.communication?.dislikesSum,
                  ),
                  command: getCoefficientText(commandDiff),
                  commandDislike: getCoefficientText(
                    dislikesTooltips.command?.dislikesSum,
                  ),
                  analytics: getCoefficientText(analyticsDiff),
                  analyticsDislike: getCoefficientText(
                    dislikesTooltips.analytics?.dislikesSum,
                  ),
                  commonCompetencies: getCompetencies({}, commonCompetencies),
                  commonCompetenciesDislikes: getCompetencies(dislikesTooltips),
                  teamRole: getRole()?.teamRole,
                  avatarProps: !isBot
                    ? {
                        alt: fullName,
                        userId: pId,
                        link: userLink,
                      }
                    : undefined,
                  columnsTextColor: {
                    communication: getGridNumberColor(communicationDiff),
                    command: getGridNumberColor(commandDiff),
                    analytics: getGridNumberColor(analyticsDiff),
                    commonCompetencies: getGridCommonColor(commonCompetencies),
                    commonCompetenciesDislikes: getGridNumberColor(-1),
                    communicationDislike: getGridNumberColor(
                      dislikesTooltips.communication?.dislikesSum || 0,
                    ),
                    analyticsDislike: getGridNumberColor(
                      dislikesTooltips.analytics?.dislikesSum || 0,
                    ),
                    commandDislike: getGridNumberColor(
                      dislikesTooltips.command?.dislikesSum || 0,
                    ),
                  },
                  buttonProps: {
                    settingsButton: {
                      disabled: !isNotEmptyResults,
                    },
                  },
                  tooltips: {
                    communicationDislike:
                      dislikesTooltips.communication?.content,
                    analyticsDislike: dislikesTooltips.analytics?.content,
                    commandDislike: dislikesTooltips.command?.content,
                  },
                });

                return final;
              },
              [],
            );

            const isNotMatch: boolean = !rows.some((item) =>
              item.fullName
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase()),
            );

            if (isNotMatch) {
              return final;
            }

            final.push({
              place,
              teamNum,
              role: players[0].teamName || "",
              rows,
            });
          }
          return final;
        },
        [],
      );
    }

    return [];
  }, [
    i18n.language,
    results,
    searchValue,
    teamColumns,
    isTeamGrid,
    gameType,
    isAdminClientId,
  ]);

  const corpRows: ICorpGridItem[] = useMemo(() => {
    if (results?.players && isCorpGrid) {
      const tmpCorpTeamsNums = results.players.reduce(
        (
          final: ICorpTeamsItem[],
          { areaNum, corpNum, place, role }: PlayerDto,
        ) => {
          const someEqual: boolean = final.some(
            (item) =>
              item.place === place &&
              item.areaNum === areaNum &&
              item.corpNum === corpNum,
          );

          if (role === Specialties.CEO) {
            areaNum = 0;
          }

          if (
            !someEqual &&
            areaNum !== undefined &&
            place !== undefined &&
            corpNum !== undefined
          ) {
            final.push({
              areaNum,
              corpNum,
              place,
            });
          }

          return final;
        },
        [],
      );

      const corpTeamsNums = tmpCorpTeamsNums.sort((a, b) => a.place - b.place);

      return corpTeamsNums.reduce(
        (
          final: ICorpGridItem[],
          { place, areaNum, corpNum }: ICorpTeamsItem,
        ) => {
          const players: PlayerDto[] | undefined = results.players?.filter(
            (item) => {
              if (item.role === Specialties.CEO) {
                item.areaNum = 0;
              }

              return (
                item.areaNum === areaNum &&
                item.place === place &&
                item.corpNum === corpNum
              );
            },
          );

          if (!!players?.length && gameType) {
            const rows = players.reduce(
              (final: IDataGridRow[], curr: PlayerDto) => {
                const {
                  commandDiff,
                  commandTenacityDiff,
                  communicationDiff,
                  communicationTenacityDiff,
                  analyticsDiff,
                  analyticsTenacityDiff,
                  jurisprudenceDiff,
                  jurisprudenceTenacityDiff,
                  thinking3dDiff,
                  thinking3dTenacityDiff,
                  economicsTenacityDiff,
                  role,
                  lastname,
                  firstname,
                  pId,
                  teamName,
                  dislikes,
                } = curr;

                const userLink: string = isAdminClientId
                  ? `/users-control/user/${pId}/info`
                  : `/ratings/rating/${pId}`;

                const isBot: boolean =
                  firstname === "Bot" && lastname === "Bot";

                const fullName: string = splitStrings([firstname, lastname]);

                const notFound: boolean = !fullName.includes(searchValue);

                const isNotEmptyResults: boolean =
                  !!curr.results && !!Object.keys(curr.results).length;

                const getRole = () => {
                  if (role) {
                    const gameTypeKey: GameType = GameType.Corp;
                    const gameTypeInfo =
                      results.rawMetaJson.gameTypes[gameTypeKey];

                    return getSimulationRole({
                      roleKey: teamName || "",
                      gameType: gameTypeInfo,
                      lang: i18n.language,
                      teamRoleKey: role,
                    });
                  }
                };

                const dislikesTooltips = dislikeTooltipFactory(
                  dislikes,
                  gameType,
                  [
                    Competence.Communication,
                    Competence.Analytics,
                    Competence.Command,
                    Competence.Jurisprudence,
                    Competence.Thinking3d,
                    Competence.AnalyticsTenacity,
                    Competence.CommunicationTenacity,
                    Competence.CommandTenacity,
                    Competence.EconomicsTenacity,
                    Competence.JurisprudenceTenacity,
                    Competence.Thinking3dTenacity,
                  ],
                );

                const commonCompetencies: Record<string, number>[] = [
                  {
                    [Competence.Thinking3d]: thinking3dDiff,
                  },
                  {
                    [Competence.Jurisprudence]: jurisprudenceDiff,
                  },
                  {
                    [Competence.AnalyticsTenacity]: analyticsTenacityDiff,
                  },
                  {
                    [Competence.CommunicationTenacity]:
                      communicationTenacityDiff,
                  },
                  {
                    [Competence.CommandTenacity]: commandTenacityDiff,
                  },
                  {
                    [Competence.EconomicsTenacity]: economicsTenacityDiff ?? 0,
                  },
                  {
                    [Competence.JurisprudenceTenacity]:
                      jurisprudenceTenacityDiff ?? 0,
                  },
                  {
                    [Competence.Thinking3dTenacity]: thinking3dTenacityDiff,
                  },
                ];

                final.push({
                  id: pId,
                  selected: false,
                  isTransparent: notFound,
                  fullName,
                  place: " ",
                  role: " ",
                  communication: getCoefficientText(communicationDiff),
                  communicationDislike: getCoefficientText(
                    dislikesTooltips.communication?.dislikesSum,
                  ),
                  command: getCoefficientText(commandDiff),
                  commandDislike: getCoefficientText(
                    dislikesTooltips.command?.dislikesSum,
                  ),
                  analytics: getCoefficientText(analyticsDiff),
                  analyticsDislike: getCoefficientText(
                    dislikesTooltips.analytics?.dislikesSum,
                  ),
                  commonCompetencies: getCompetencies({}, commonCompetencies),
                  commonCompetenciesDislikes: getCompetencies(dislikesTooltips),
                  teamRole: getRole()?.teamRole,
                  avatarProps: !isBot
                    ? {
                        alt: fullName,
                        userId: pId,
                        link: userLink,
                      }
                    : undefined,
                  columnsTextColor: {
                    communication: getGridNumberColor(communicationDiff),
                    command: getGridNumberColor(commandDiff),
                    analytics: getGridNumberColor(analyticsDiff),
                    commonCompetencies: getGridCommonColor(commonCompetencies),
                    commonCompetenciesDislikes: getGridNumberColor(-1),
                    communicationDislike: getGridNumberColor(
                      dislikesTooltips.communication?.dislikesSum || 0,
                    ),
                    analyticsDislike: getGridNumberColor(
                      dislikesTooltips.analytics?.dislikesSum || 0,
                    ),
                    commandDislike: getGridNumberColor(
                      dislikesTooltips.command?.dislikesSum || 0,
                    ),
                  },
                  buttonProps: {
                    settingsButton: {
                      disabled: !isNotEmptyResults,
                    },
                  },
                  tooltips: {
                    communicationDislike:
                      dislikesTooltips.communication?.content,
                    analyticsDislike: dislikesTooltips.analytics?.content,
                    commandDislike: dislikesTooltips.command?.content,
                  },
                });

                return final;
              },
              [],
            );

            const isNotMatch: boolean = !rows.some((item) =>
              item.fullName
                .toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase()),
            );

            if (isNotMatch) {
              return final;
            }

            final.push({
              place,
              areaNum,
              corpNum: players[0].corpNum as number,
              role: players[0].teamName || "",
              rows,
            });
          }

          return final;
        },
        [],
      );
    }

    return [];
  }, [
    i18n.language,
    results,
    searchValue,
    isTeamGrid,
    gameType,
    isAdminClientId,
  ]);

  const getTeamRoleTitle = (role: string) => {
    if (gameType && (isTeamGrid || isCorpGrid)) {
      return getSimulationRole({
        roleKey: role,
        lang: i18n.language,
        gameType,
        teamRoleKey: "empty",
      }).role;
    }

    return "";
  };

  useEffect(() => {
    return () => {
      cleanAssessmentResults();
    };
  }, []);

  return (
    <>
      <div className="assessment-results-popup--buttons-export">
        <BaseButton
          onClick={() => {
            exportAssessmentHistoryXlsx({ assessmentId: itemId });
            postExportSessionsHistory({
              assessmentHistoryCreate: {
                assessment_id: itemId,
                role: isAdminClientId ? "Admin" : "Hr",
              },
            });
          }}
          disabled={!exportPermission}
        >
          <TextWithIcon
            label={`${t("common.unload")} XLSX`}
            iconName={"upload-blue"}
            isTextNowrap
          />
        </BaseButton>
        <BaseButton
          onClick={() => {
            exportAssessmentHistoryCsv({ assessmentId: itemId });
            postExportSessionsHistory({
              assessmentHistoryCreate: {
                assessment_id: itemId,
                role: isAdminClientId ? "Admin" : "Hr",
              },
            });
          }}
          disabled={!exportPermission}
        >
          <TextWithIcon
            label={`${t("common.unload")} CSV`}
            iconName={"upload-blue"}
            isTextNowrap
          />
        </BaseButton>
      </div>
      <div
        className={classNames("assessment-results-popup", {
          "assessment-results-popup--solo": isSoloGrid,
          "assessment-results-popup--team": isTeamGrid,
          "assessment-results-popup--corp": isCorpGrid,
        })}
      >
        {isLoading && <BaseLoader />}
        {isLoaded && (
          <PopupGridGroup
            searchValue={searchValue}
            isTeamGrid={isTeamGrid}
            isCorpGrid={isCorpGrid}
            soloColumns={soloColumns}
            soloRows={soloRows}
            teamColumns={teamColumns}
            teamRows={teamRows}
            corpColumns={teamColumns}
            corpRows={corpRows}
            onChangeSearch={onChangeSearch}
            soloClassName="assessment-results-popup--solo"
            teamClassName="assessment-results-popup--team"
            corpClassName="assessment-results-popup--corp"
            getTeamRoleTitle={getTeamRoleTitle}
          />
        )}
        {isEmptyResultsReady && (
          <EmptyBlock
            title={t("popup.assessmentResults.emptyBlock.noPlayers")}
          />
        )}
        {isError && <EmptyBlock title={t("alert.requestError")} />}
      </div>
    </>
  );
};
