import {
  ChangeEvent,
  FC,
  FocusEvent,
  ReactElement,
  useEffect,
  useState,
} from "react";

import { IBaseInput } from "../../../models/base-input";
import { ERegEx, regExList } from "../../../constants/regExList";

import { CountryCode } from "libphonenumber-js/types";

import { formatPhone } from "../../../helpers/phone";
import { InputTemplate } from "../input-template/input-template";

interface Props extends IBaseInput {
  regionCode?: CountryCode;
  customRegEx?: RegExp;
  hidePartially?: boolean;
}

export const BaseInputPhone: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  onChange,
  regionCode = "RU",
  customRegEx,
  required,
  isSmall,
  forDarkBg,
  isContextBtnDisabled,
  readOnly,
  onRefresh,
  onDelete,
  hidePartially = false,
  onFocus,
}): ReactElement => {
  const onChangeHandler = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const target = e.target as HTMLInputElement;

    const regEx = customRegEx || regExList[ERegEx.NOT_PLUS_AND_NUMBERS];

    const formattedPhone = await formatPhone(
      target.value,
      undefined,
      regionCode,
    );

    const localValue: string =
      formattedPhone !== undefined ? formattedPhone : target.value;

    const formattedLocalValue = localValue.replace(regEx, "");

    onChange({
      value: formattedLocalValue,
      name: target.name,
    });
  };

  const [inputValue, setInputValue] = useState<string>(value);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    formatPhone(value).then((formattedPhone) => {
      if (!hidePartially || !formattedPhone || isFocused) {
        return setInputValue(formattedPhone || "");
      }

      const temp = formattedPhone.split(" ");

      if (temp.length <= 2) {
        temp.splice(1, 1, "*".repeat(temp[1]?.length - 2) + temp[1]?.slice(-2));
      } else {
        temp.splice(
          1,
          temp.length - 2,
          "*".repeat(temp.slice(1, -1).join("").length),
        );
      }

      setInputValue(temp.join(" "));
    });
  }, [value, isFocused, hidePartially]);

  const focusHandler = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  return (
    <InputTemplate
      type="tel"
      value={inputValue}
      onChange={onChangeHandler}
      name={name}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      maxLength={20}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      onRefresh={onRefresh}
      onDelete={onDelete}
      onFocus={focusHandler}
      onBlur={() => setIsFocused(false)}
    />
  );
};
