import classNames from "classnames";
import React, { FC, ReactElement, ReactNode } from "react";
import { getSimulationParamType } from "../../helpers/getSimulationParamType";
import {
  ESimulationArrayParamType,
  ESimulationParamsType,
  TParams,
} from "../../store/types";
import "./params-info-item.scss";
import { TextWithIcon } from "../text-with-icon/text-with-icon";
import { BaseButton } from "../base-button/base-button";

interface Props {
  title: string;
  direction?: "column" | "row";
  type?: ESimulationParamsType;
  subType?: ESimulationArrayParamType;
  param?: TParams;
  isNoWrap?: boolean;
  className?: string;
  isBoldTitle?: boolean;
  isBoldDescription?: boolean;
  isEditable?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputOnClick?: (arg: number) => void;
  onAddHandler?: () => void;
  paramKey?: any;
  onDeleteHandler?: (index: number) => void;
  onCheck?: (param: boolean, key: string) => void;
  children?: ReactNode;
}

export const ParamsInfoItem: FC<Props> = ({
  direction = "column",
  type,
  subType,
  title,
  onChange,
  param,
  isNoWrap,
  className,
  paramKey,
  children,
  isBoldTitle = true,
  isBoldDescription = false,
  isEditable,
  inputOnClick,
  onAddHandler,
  onCheck,
  onDeleteHandler,
}): ReactElement => {
  const { isArrayString, isArrayNumber, isBoolean, isNumber, isString } =
    getSimulationParamType({
      templateParam: { type, subType },
    });
  const isStrNum: boolean = isNumber || isString;
  const isRow: boolean = direction === "row";

  return (
    <div
      className={classNames(
        "params-info-item",
        `params-info-item--${direction}`,
        className,
      )}
    >
      <h4
        onClick={() => {
          if (!isEditable) return;
          if (typeof param !== "boolean") return;
          if (onCheck) {
            onCheck(param, paramKey);
          }
        }}
        className={classNames("params-info-item__title", {
          "params-info-item__title--bold": isBoldTitle,
          "params-info-item__title--icon": isBoolean,
          "params-info-item__title--icon_checked": param,
          "params-info-item__title--icon_unchecked": !param,
          "params-info-item__title--check":
            isEditable && typeof param === "boolean",
        })}
      >
        {title}
      </h4>
      {isStrNum && (
        <div
          className={classNames(
            "params-info-item__description",
            `params-info-item__description--${direction}`,
            {
              "params-info-item__description--no-wrap":
                isNoWrap && isStrNum && isRow,
              "params-info-item__description--bold": isBoldDescription,
            },
          )}
        >
          {isEditable ? (
            <input
              type="number"
              className="params-info-item__description--input"
              value={(param as string).toString()}
              onWheel={(e) => {
                e.currentTarget.blur();
                e.stopPropagation();
              }}
              onChange={onChange}
            />
          ) : (
            param
          )}
        </div>
      )}
      {(isArrayNumber || isArrayString) && (
        <ul
          className={classNames("params-info-item__list", {
            "params-info-item__list--column": isArrayString,
            "params-info-item__list_editable": isEditable,
          })}
        >
          {(param as Array<string | number>).map((item, index) => {
            return (
              <li key={title + index} className={"params-info-item__list-item"}>
                <div
                  className={classNames("params-info-item__wrapper", {
                    "params-info-item__wrapper_editable": isEditable,
                  })}
                >
                  <span
                    className={classNames("params-info-item__sub-description", {
                      "params-info-item__sub-description_editable": isEditable,
                    })}
                  >
                    #{index + 1}
                  </span>
                  {!isEditable ? (
                    <span className="params-info-item__description ">
                      {item}
                    </span>
                  ) : (
                    <input
                      type="number"
                      className="params-info-item__description--input"
                      value={item}
                      onWheel={(e) => {
                        e.currentTarget.blur();
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        if (onChange && e.target.value.length < 7) {
                          onChange(e);
                        }
                      }}
                      onClick={() => {
                        if (!inputOnClick) return;
                        inputOnClick(index);
                      }}
                      onFocus={() => {
                        if (!inputOnClick) return;
                        inputOnClick(index);
                      }}
                    />
                  )}
                </div>
                {isEditable && index !== 0 && onDeleteHandler && (
                  <p
                    className="params-info-item__delete_turn"
                    onClick={() => onDeleteHandler(index)}
                  >
                    Удалить
                  </p>
                )}
              </li>
            );
          })}
          {isEditable && (
            <li className="params-info-item__list-item">
              <BaseButton
                large
                className={classNames(
                  "base-array-field__list-add",
                  "base-array-field__list-add--number-array",
                  "params-info-item__add_turn_btn",
                )}
                onClick={onAddHandler}
              >
                <TextWithIcon
                  className="params-info-item__add_turn"
                  iconName="plus-blue"
                />
              </BaseButton>
            </li>
          )}
        </ul>
      )}
      {children}
    </div>
  );
};
