import {
  ChangeEvent,
  FC,
  FocusEvent,
  ReactElement,
  useMemo,
  useState,
} from "react";
import { ERegEx, regExList } from "../../../constants/regExList";
import { IBaseInput } from "../../../models/base-input";
import { InputTemplate } from "../input-template/input-template";

interface Props extends IBaseInput {
  hidePartially?: boolean;
}

export const BaseInputEmail: FC<Props> = ({
  value = "",
  name,
  placeholder,
  error = false,
  disabled,
  onChange,
  required,
  isSmall,
  forDarkBg,
  isContextBtnDisabled,
  readOnly,
  onDelete,
  onRefresh,
  onFocus,
  hidePartially = false,
}): ReactElement => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    const localValue = target.value.replace(regExList[ERegEx.ONLY_SPACES], "");

    onChange({
      value: localValue,
      name: target.name,
    });
  };

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const displayedValue = useMemo(() => {
    const parts = value.split("@");

    if (
      isFocused ||
      !hidePartially ||
      !regExList[ERegEx.VALID_EMAIL].test(value)
      // TODO тест проходит ли регекс @mail.ru
      // || !value.length
    ) {
      return value;
    }

    const visibleLength = parts[0].length <= 3 ? 1 : 2;

    parts[0] =
      parts[0].slice(0, visibleLength) + "***" + parts[0].slice(-visibleLength);

    return parts.join("@");
  }, [value, hidePartially, isFocused]);

  const focusHandler = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(event);
  };

  return (
    <InputTemplate
      type="email"
      value={displayedValue}
      onChange={onChangeHandler}
      name={name}
      disabled={disabled}
      error={error}
      isSmall={isSmall}
      forDarkBg={forDarkBg}
      required={required}
      placeholder={placeholder}
      isContextBtnDisabled={isContextBtnDisabled}
      readOnly={readOnly}
      onRefresh={onRefresh}
      onDelete={onDelete}
      onFocus={focusHandler}
      onBlur={() => setIsFocused(false)}
    />
  );
};
