import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DataGrid } from "../";

import {
  EDataGridColumnType,
  EDataGridFilterPosition,
  EDataGridFilterType,
  EDataGridTextColor,
  IDataGridColumn,
  IDataGridRow,
  IDataGridSelectOption,
  TDataGridDataParams,
  TDataGridPagination,
} from "../../models/dataGrid";

import { PAssessmentBriefDto } from "../../../generated/statistic";
import { languagePicker } from "../../helpers/languagePicker";
import { useTranslation } from "react-i18next";
import useClient from "../../../shared/hooks/useClient";

import { AssessmentType, GameType } from "../../../generated/game";
import { getActiveGroup } from "../../helpers/data-grids/getActiveGroup";
import { getOrderByValue } from "../../helpers/data-grids/getOrderByValue";
import {
  EPopupDataIdName,
  EPopupName,
  popupModel,
} from "src/shared/components";
import {
  Permissions,
  useCheckPermission,
} from "src/shared/hooks/useCheckPermission";

interface Props {
  historyItems: PAssessmentBriefDto[];
  loading?: boolean;
  queryParams?: TDataGridDataParams;
  onUpdateSortAndFilters?: (params: TDataGridDataParams) => void;
  pagination?: TDataGridPagination | null;
  goToPage?: (pageNum: number) => void;
  readmore?: (pageNum: number) => void;
  onSelectRows?: (ids: string[]) => void;
  selectedRowsIds: string[];
}

const { openPopup } = popupModel;

export const ArchiveTable: FC<Props> = ({
  loading,
  historyItems,
  queryParams,
  onUpdateSortAndFilters,
  pagination,
  goToPage,
  readmore,
  onSelectRows,
  selectedRowsIds,
}): ReactElement => {
  const { isHrClientId, isAdminClientId } = useClient();

  const { i18n, t } = useTranslation();

  const resultsPermission = useCheckPermission(Permissions.SessionsResults);

  const [columns, setColumns] = useState<IDataGridColumn[]>([]);
  const [rows, setRows] = useState<IDataGridRow[]>([]);

  const assessmentTypes = useMemo(() => Object.values(AssessmentType), []);
  const gameTypes = useMemo(() => Object.values(GameType), []);

  const assessmentTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return assessmentTypes.map((type) => ({
      value: type,
      label: t(`common.assessment.${type}`, type),
    }));
  }, [assessmentTypes, t]);

  const gameTypeOptions = useMemo<IDataGridSelectOption[]>(() => {
    return gameTypes.map((type) => ({
      value: type,
      label: t(`common.assessment.${type}`, type),
    }));
  }, [gameTypes, t]);

  const showAssessmentResults = useCallback((rowId: string): void => {
    openPopup({
      name: EPopupName.ASSESSMENT_RESULT,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
    });
  }, []);

  const showAssessmentParameters = useCallback((rowId: string): void => {
    openPopup({
      name: EPopupName.ASSESSMENT_PARAMETERS,
      dataId: [{ name: EPopupDataIdName.DEFAULT, value: rowId }],
    });
  }, []);

  const generatedColumns = useMemo<IDataGridColumn[]>(() => {
    const newColumns: IDataGridColumn[] = [
      {
        title: "SELECT",
        type: EDataGridColumnType.SELECT_ROW,
        key: "selected",
        showed: true,
        hiddenTitle: true,
        sticky: {
          mobile: {
            left: 0,
          },
          tablet: {
            left: 0,
          },
          laptop: {
            left: 0,
          },
        },
      },
      {
        title: t("table.header.session"),
        type: EDataGridColumnType.STRING,
        key: "sessionName",
        showed: true,
        filterTitle: t("table.header.session"),
        controlPanel: {
          title: t("table.header.session"),
          activeGroupKey: getActiveGroup(
            ["title"],
            { all: ["title"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "title",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.title || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "title",
                  "-title",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "title",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-title",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.simulation"),
        type: EDataGridColumnType.STRING,
        key: "simulationName",
        showed: true,
        controlPanel: {
          title: t("table.header.simulation"),
          activeGroupKey: getActiveGroup(
            ["gameTitle"],
            { all: ["gameTitle"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "gameTitle",
                  type: EDataGridFilterType.SEARCH,
                  placeholder: t("common.enterValue"),
                  value: queryParams?.gameTitle || "",
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "gameTitle",
                  "-gameTitle",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.alphabet.asc"),
                    value: "gameTitle",
                  },
                  {
                    title: t("table.filters.alphabet.desc"),
                    value: "-gameTitle",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.type"),
        type: EDataGridColumnType.STRING,
        key: "assessmentType",
        showed: true,
        controlPanel: {
          title: t("table.header.type"),
          activeGroupKey: getActiveGroup(
            ["assessmentType"],
            { all: ["assessmentType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "assessmentType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: "Не выбрано",
                  value: queryParams?.assessmentType || "",
                  items: assessmentTypeOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.mode"),
        type: EDataGridColumnType.STRING,
        key: "gameType",
        showed: true,
        controlPanel: {
          title: t("table.header.mode"),
          activeGroupKey: getActiveGroup(
            ["gameType"],
            { all: ["gameType"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "gameType",
                  type: EDataGridFilterType.CHECKBOX,
                  placeholder: "Не выбрано",
                  value: queryParams?.gameType || "",
                  items: gameTypeOptions,
                },
              ],
            },
          ],
        },
      },
      {
        title: t("table.header.members"),
        type: EDataGridColumnType.STRING,
        key: "players",
        showed: true,
        controlPanel: {
          title: t("table.header.members"),
          activeGroupKey: getActiveGroup(
            ["curPlayers", "curPlayersFrom", "curPlayersTo"],
            { all: ["curPlayers", "curPlayersFrom", "curPlayersTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "curPlayersFrom",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.curPlayersFrom || "",
                  isHalf: true,
                },
                {
                  key: "curPlayersTo",
                  type: EDataGridFilterType.NUMBER,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.curPlayersTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "curPlayers",
                  "-curPlayers",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.ascending"),
                    value: "curPlayers",
                  },
                  {
                    title: t("table.filters.descending"),
                    value: "-curPlayers",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: t("table.header.game.end"),
        type: EDataGridColumnType.DATETIME,
        key: "finished",
        showed: true,
        filterPosition: EDataGridFilterPosition.LEFT,
        controlPanel: {
          title: t("table.header.game.end"),
          activeGroupKey: getActiveGroup(
            ["finished", "finishedFrom", "finishedTo"],
            { all: ["finished", "finishedFrom", "finishedTo"] },
            queryParams,
          ),
          groups: [
            {
              key: "all",
              filters: [
                {
                  key: "finishedFrom",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.from"),
                  value: queryParams?.finishedFrom || "",
                  isHalf: true,
                },
                {
                  key: "finishedTo",
                  type: EDataGridFilterType.DATE,
                  placeholder: t("table.filters.to"),
                  value: queryParams?.finishedTo || "",
                  isHalf: true,
                },
              ],
              sort: {
                value: getOrderByValue(queryParams?.orderBy, [
                  "finished",
                  "-finished",
                ]),
                orderKey: "orderBy",
                items: [
                  {
                    title: t("table.filters.date.desc"),
                    value: "-finished",
                  },
                  {
                    title: t("table.filters.date.asc"),
                    value: "finished",
                  },
                ],
              },
            },
          ],
        },
      },
    ];

    if (isHrClientId || isAdminClientId) {
      const additionalColumns: IDataGridColumn[] = [
        {
          title: t("table.header.findMember"),
          type: EDataGridColumnType.BUTTON,
          key: "resultsButtonTitle",
          showed: resultsPermission,
          controlPanel: {
            title: t("table.header.findMember"),
            activeGroupKey: getActiveGroup(
              ["firstName", "lastName"],
              { all: ["firstName", "lastName"] },
              queryParams,
            ),
            groups: [
              {
                key: "all",
                filters: [
                  {
                    key: "firstName",
                    type: EDataGridFilterType.SEARCH,
                    placeholder: t("table.header.name"),
                    value: queryParams?.firstName || "",
                  },
                  {
                    key: "lastName",
                    type: EDataGridFilterType.SEARCH,
                    placeholder: t("table.header.lastName"),
                    value: queryParams?.lastName || "",
                  },
                ],
              },
            ],
          },
          noPaddingRight: true,
          buttonProps: {
            primary: true,
            xSmall: true,
            xSmallRounded: true,
            onClick: showAssessmentResults,
          },
          sticky: {
            tablet: {
              right: 59,
            },
            laptop: {
              right: 79,
            },
          },
        },
        {
          title: t("table.header.settings"),
          hiddenTitle: true,
          type: EDataGridColumnType.BUTTON,
          key: "settingsButtonTitle",
          showed: isHrClientId || isAdminClientId,
          buttonProps: {
            small: true,
            hideLabel: true,
            transparent: true,
            icon: "props-gray",
            noPaddingX: true,
            onClick: showAssessmentParameters,
            ariaLabel: t("buttons.sessionSettings"),
          },
          sticky: {
            tablet: {
              right: 0,
              width: 59,
            },
            laptop: {
              right: 0,
            },
          },
        },
      ];

      newColumns.push(...additionalColumns);
    }

    return newColumns;
  }, [
    queryParams,
    showAssessmentParameters,
    showAssessmentResults,
    isHrClientId,
    isAdminClientId,
    assessmentTypeOptions,
    gameTypeOptions,
    t,
  ]);

  useEffect(() => {
    setColumns(generatedColumns);
  }, [generatedColumns]);

  useEffect(() => {
    const rowsItems: IDataGridRow[] = historyItems.map(
      (item): IDataGridRow => ({
        id: item.aId || "",
        selected: false,
        sessionName: item.aTitle,
        simulationName: languagePicker(item.gTitle, i18n.language),
        assessmentType: t(`common.assessment.${item.aType}`, item.aType),
        gameType: t(`common.gameType.${item.gType}`, item.gType),
        players: `${item.curPlayers}/${item.maxPlayers}`,
        created: item.createdAt,
        started: item.startedAt,
        finished: item.finishedAt,
        resultsButtonTitle: t("table.header.results"),
        settingsButtonTitle: t("table.header.settings"),
        columnsTextColor: {
          rating: EDataGridTextColor.BLUE,
        },
        tooltips: {
          finished: {
            items: [
              {
                key: t("table.header.game.created"),
                value: item.createdAt,
                type: "datetime",
              },
              {
                key: t("table.header.game.run"),
                value: item.startedAt,
                type: "datetime",
              },
              {
                key: t("table.header.game.end"),
                value: item.finishedAt,
                type: "datetime",
              },
            ],
          },
        },
      }),
    );

    setRows(rowsItems);
  }, [historyItems, i18n.language, t]);
  return (
    <DataGrid
      rows={rows}
      setRows={setRows}
      columns={columns}
      setColumns={setColumns}
      className={"session-history-table"}
      queryParams={queryParams}
      onUpdateSortAndFilters={onUpdateSortAndFilters}
      onUpdateSelectedRows={onSelectRows}
      selectedRowsIds={selectedRowsIds}
      loading={loading}
      titleUppercase
      nowrap
      pagination={pagination}
      readmore={readmore}
      goToPage={goToPage}
    />
  );
};
