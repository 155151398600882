import { BaseButton } from "src/shared/components";
import { CodeInput } from "../code-input/code-input";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import styles from "./join-by-code.module.scss";
import { useNavigate } from "react-router-dom";

export const JoinByCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [code, setCode] = useState<string>("");

  const clickHandler = () => {
    if (code.length < 6) {
      return;
    }

    navigate(`/sessions/code/${code}`);
  };

  return (
    <div className={styles.join_code}>
      <h4>{t("profile.join.code")}</h4>
      <CodeInput
        value={code}
        onChange={(e) => {
          console.log(e);
          setCode(e);
        }}
      />
      <BaseButton primary onClick={clickHandler}>
        {t("game.button.connect")}
      </BaseButton>
    </div>
  );
};
