import {
  Dispatch,
  FC,
  FormEvent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";

import { SmartCaptcha } from "@yandex/smart-captcha";

import { reflect } from "@effector/reflect";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { userFormModel } from "src/features/public/user-form";

import { keycloakModel } from "src/entities/public/keycloak";

import {
  getFieldsNamesForClean,
  TFormDataStringKeys,
} from "src/features/public/user-form/lib/getFieldsNamesForClean";

import {
  BaseGroup,
  BaseInputDate,
  BaseInputEmail,
  BaseInputPhone,
  BaseInputText,
  BaseMultiSelect,
  BaseSelect,
  FormControl,
  InputCheckbox,
  SelectItem,
} from "../";

import { UserTypeEnum } from "../../models/user";

import {
  IBaseCheckboxChange,
  IBaseInputChange,
  IBaseMultiSelectChange,
} from "../../models/base-input";

import { toCloneObject } from "../../helpers/toCloneObject";

import styles from "./user-form.module.scss";
import classNames from "classnames";
import { Checkbox } from "../../models";

// noinspection JSUnusedGlobalSymbols
export enum EDesiredStudyPlace {
  NOT_AN_ENTRANT = "notAnEntrant",
  LEADERSHIP_PROGRAM = "leadershipProgram",
  ALABUGA_POLYTECH = "alabugaPolytech",
  KFU = "KFU",
  ALABUGA_START = "alabugaStart",
}

// noinspection JSUnusedGlobalSymbols
export enum EKfu {
  TEACHER_OF_MATHEMATICS_AND_PHYSICS = "teacherOfMathematicsAndPhysics",
  PRE_SCHOOL_TEACHER = "preschoolTeacher",
  JURISPRUDENCE = "jurisprudence",
  ECONOMY = "economy",
  APPLIED_INFORMATICS = "appliedInformatics",
  FUNDAMENTAL_AND_APPLIED_CHEMISTRY = "fundamentalAndAppliedChemistry",
  MECHATRONICS = "mechatronics",
}

// noinspection JSUnusedGlobalSymbols
export enum EAlabugaPolytech {
  INDUSTRIAL_AUTOMATION = "industrialAutomation",
  INDUSTRIAL_ROBOTICS = "industrialRobotics",
  ELECTRICAL_INSTALLATION = "electricalInstallation",
  LABORATORY_CHEMICAL_ANALYSIS = "laboratoryChemicalAnalysis",
  PYTHON_PROGRAMMING = "pythonProgramming",
  BIM_DESIGN_OF_BUILDINGS_AND_STRUCTURES = "bimDesignOfBuildingsAndStructures",
  MICROELECTRONICS = "microelectronics",
  JURISPRUDENCE = "jurisprudence",
  ECONOMY = "economy",
  BUSINESS_INFORMATICS_1C = "businessInformatics1c",
  MEDICAL_BUSINESS = "Medicine",
  INTERNATIONAL_PEDAGOGY = "InternationalPedagogy",
  INDUSTRIAL_MACHINERY = "IndustrialMachinery",
  CNC = "CNC",
  PROGRAMMING_MICROCONTROLLERS_IN_C_LANGUAGE = "ProgrammingMicrocontrollersInCLanguage",
  INDUSTRIAL_SAFETY = "IndustrialSafety",
}

// noinspection JSUnusedGlobalSymbols
export enum EAlabugaStart {
  LOGISTICS = "logistics",
  TOWER_CRANE_OPERATOR = "towerCraneOperator",
  WELDING_ENGINEERING = "weldingEngineering",
  HEAVY_EQUIPMENT_OPERATOR = "heavyEquipmentOperator",
  SERVICE_AND_HOSPITALITY = "serviceAndHospitality",
  CATERING_INDUSTRY = "cateringIndustry",
  FRONT_DESK_SERVICES = "frontDeskServices",
  TILING_WORK = "tilingWork",
  INSTALLATION_WORK = "installationWork",
  PRODUCTION_OPERATOR = "productionOperator",
}

export interface FormDataInterface {
  firstName: string;
  middleName: string;
  lastName: string;
  login: string;
  email: string;
  phoneNumber: string;
  gender: string;
  country: string;
  region: string;
  district: string;
  locality: string;
  school: string;
  groupCode: string;
  birthdate: string;
  supervisedUnit: string;
  studyWorkPlace: string;
  workPhoneNumber: string;
  avatarUrl: string;
  resumeUrl: string;
  resumeFileName: string;
  desiredStudyPlace: string;
  desiredSpecialty: string[];
  isStudent?: boolean;
  isEmployee?: boolean;
}

interface Props {
  errors?: Record<string, string>;
  setFormErrors?: Dispatch<SetStateAction<Record<string, string>>>;
  userType: string;
  isPhoneNumberDisabled?: boolean;
  onSubmit: (e: FormEvent) => void;
  formData: FormDataInterface;
  setFormData: Dispatch<SetStateAction<FormDataInterface>>;
  emailVerified?: boolean;
  onRepeatVerifiedEmail?: () => void;
  isRepeatVerifiedEmailLoading?: boolean;
  requiredFields?: { [key: string]: boolean };
  countriesSelectItems: SelectItem[];
  regionsSelectItems: SelectItem[];
  districtsSelectItems: SelectItem[];
  schoolsSelectItems: SelectItem[];
  isPlayer: boolean;
  isHr: boolean;
  isAdmin: boolean;
  isAdminClientId: boolean;
  isReCaptchaShow?: boolean;
  setRecaptchaToken?: (key: string) => void;
  confirmedStudent: boolean;
  confirmedEmployee: boolean;
  isWithoutCheckbox?: boolean;
  disabled?: boolean;
  hidePersonalData?: boolean;
}

const desiredStudyPlaceList = Object.values(
  EDesiredStudyPlace,
) as EDesiredStudyPlace[];

const KFUList = Object.values(EKfu) as EKfu[];

const AlabugaPolytechList = Object.values(
  EAlabugaPolytech,
) as EAlabugaPolytech[];

const AlabugaStartList = Object.values(EAlabugaStart) as EAlabugaStart[];

const genderList: string[] = ["male", "female"];

const View: FC<Props> = ({
  errors,
  setFormErrors,
  userType,
  formData,
  isPhoneNumberDisabled,
  onSubmit,
  setFormData,
  emailVerified = false,
  isRepeatVerifiedEmailLoading = false,
  onRepeatVerifiedEmail,
  requiredFields,
  countriesSelectItems,
  regionsSelectItems,
  districtsSelectItems,
  schoolsSelectItems,
  isPlayer,
  isHr,
  isAdmin,
  isAdminClientId,
  setRecaptchaToken,
  isReCaptchaShow = true,
  confirmedStudent,
  confirmedEmployee,
  isWithoutCheckbox,
  disabled = false,
  hidePersonalData = false,
}): ReactElement => {
  const { t, i18n } = useTranslation();

  const desiredStudyPlaceItems: SelectItem[] = useMemo(
    () =>
      desiredStudyPlaceList.map((value) => ({
        label: t(`userForm.select.desiredStudyPlace.${value}`),
        value,
      })),
    [t],
  );

  const KFUItems: SelectItem[] = useMemo(
    () =>
      KFUList.map((value) => ({
        label: t(`userForm.multiSelect.KFU.${value}`),
        value,
      })),
    [t],
  );

  const alabugaPolytechItems: SelectItem[] = useMemo(
    () =>
      AlabugaPolytechList.map((value) => ({
        label: t(`userForm.multiSelect.alabugaPolytech.${value}`),
        value,
      })),
    [t],
  );

  const alabugaStartItems: SelectItem[] = useMemo(
    () =>
      AlabugaStartList.map((value) => ({
        label: t(`userForm.multiSelect.alabugaStart.${value}`),
        value,
      })),
    [t],
  );

  useMemo(
    () =>
      genderList.map((value) => ({
        label: t(`userForm.select.gender.${value}`),
        value,
      })),
    [t],
  );

  const cleanDesiredSpecialty = () => {
    setFormData((prev) => ({ ...prev, desiredSpecialty: [] }));
  };

  const cleanFormErrors = (name: string): void => {
    if (setFormErrors) {
      setFormErrors((prev) => {
        const localPrev = toCloneObject(prev);

        delete localPrev[name];

        return localPrev;
      });
    }
  };

  const onChangeHandler = ({
    name,
    value,
  }: IBaseInputChange | IBaseMultiSelectChange | IBaseCheckboxChange): void => {
    if (!!name) {
      const fieldName = name as keyof FormDataInterface;

      const fieldsForClean: TFormDataStringKeys = getFieldsNamesForClean(
        fieldName,
        isPlayer,
      );

      setFormData((prev) => {
        const localPrev = { ...prev };

        fieldsForClean.forEach((item) => {
          if (!item) {
            return;
          }
          if (item === "isStudent" || item === "isEmployee") {
            localPrev[item] = false;
          } else {
            localPrev[item] = "";
          }
        });

        return {
          ...localPrev,
          [fieldName]: value,
        };
      });

      if (!!value) {
        cleanFormErrors(name);
      }
    }
  };

  const getEmailError = () => {
    if (errors?.email) {
      return errors.email;
    }

    if (emailVerified) {
      return t("profile.info.emailStatus.notVerified");
    }
  };

  const SupportDescription = useMemo(
    () => (
      <div className={styles.supportDescription}>
        {t("userForm.description.switchNumber")}
        <Link
          to="#"
          className={styles.supportDescription__link}
          onClick={() => scrollToBottom()}
        >
          {t("userForm.description.toSupport")}
        </Link>
      </div>
    ),
    [t],
  );

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    userFormModel.setUserType(userType as UserTypeEnum);
  }, [userType]);

  useEffect(() => {
    userFormModel.changeFormData(formData);
  }, [formData]);

  useEffect(() => {
    if (!isAdmin) {
      userFormModel.fetchCountries({ pageSize: "1000", locale: i18n.language });
    }
  }, [i18n.language, isAdmin]);

  let desiredSpecialtyItems = KFUItems;

  if (formData.desiredStudyPlace === EDesiredStudyPlace.ALABUGA_POLYTECH) {
    desiredSpecialtyItems = alabugaPolytechItems;
  }
  if (formData.desiredStudyPlace === EDesiredStudyPlace.ALABUGA_START) {
    desiredSpecialtyItems = alabugaStartItems;
  }

  const isAlabugaStudent =
    formData.desiredStudyPlace === EDesiredStudyPlace.KFU ||
    formData.desiredStudyPlace === EDesiredStudyPlace.ALABUGA_POLYTECH ||
    formData.desiredStudyPlace === EDesiredStudyPlace.ALABUGA_START;

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      {/* кнопка для работы "onSubmit" по нажатию "Enter" */}
      <button style={{ display: "none" }} />

      <h3 className={styles.fieldsetTitle}>
        {t("userForm.fieldset.personalData")}
      </h3>
      <div className={styles.grid}>
        <FormControl error={errors?.firstName}>
          <BaseInputText
            error={!!errors?.firstName}
            name="firstName"
            value={formData.firstName}
            onChange={onChangeHandler}
            placeholder={t("userForm.inputLabel.firstName")}
            maxLength={30}
            required={requiredFields?.firstName}
            hidePartially={hidePersonalData}
            disabled={disabled}
            onFocus={() =>
              hidePersonalData &&
              onChangeHandler({ name: "firstName", value: "" })
            }
          />
        </FormControl>
        <FormControl error={errors?.lastName}>
          <BaseInputText
            error={!!errors?.lastName}
            name="lastName"
            value={formData.lastName}
            onChange={onChangeHandler}
            placeholder={t("userForm.inputLabel.lastName")}
            maxLength={30}
            required={requiredFields?.lastName}
            hidePartially={hidePersonalData}
            disabled={disabled}
            onFocus={() =>
              hidePersonalData &&
              onChangeHandler({ name: "lastName", value: "" })
            }
          />
        </FormControl>
        <FormControl error={errors?.middleName}>
          <BaseInputText
            error={!!errors?.middleName}
            name="middleName"
            value={formData.middleName}
            onChange={onChangeHandler}
            placeholder={t("userForm.inputLabel.middleName")}
            required={requiredFields?.middleName}
            hidePartially={hidePersonalData}
            disabled={disabled}
            onFocus={() =>
              hidePersonalData &&
              onChangeHandler({ name: "middleName", value: "" })
            }
          />
        </FormControl>
        <BaseGroup
          className={styles.baseGroup}
          left={
            <FormControl error={errors?.birthdate}>
              <BaseInputDate
                name="birthdate"
                placeholder={t("userForm.inputLabel.birthdate")}
                onChange={onChangeHandler}
                value={formData.birthdate}
                error={!!errors?.birthdate}
                required={requiredFields?.birthdate}
                disabled={disabled}
                hidePartially={hidePersonalData}
                onFocus={() =>
                  hidePersonalData &&
                  onChangeHandler({ name: "birthdate", value: "" })
                }
              />
            </FormControl>
          }
          right={
            <FormControl error={errors?.gender}>
              <div
                className={classNames(styles.genderSelect, {
                  [styles.disabled]: disabled,
                })}
              >
                <div
                  className={classNames(styles.genderSelect__item, {
                    [styles.genderSelect__itemSelected]:
                      formData.gender === "male",
                  })}
                  onClick={() =>
                    onChangeHandler({
                      name: "gender",
                      value: "male",
                    })
                  }
                >
                  {t("userForm.select.gender.male.short")}
                </div>
                <div
                  className={classNames(styles.genderSelect__item, {
                    [styles.genderSelect__itemSelected]:
                      formData.gender === "female",
                  })}
                  onClick={() =>
                    onChangeHandler({
                      name: "gender",
                      value: "female",
                    })
                  }
                >
                  {t("userForm.select.gender.female.short")}
                </div>
              </div>
            </FormControl>
          }
        />
        <FormControl
          error={errors?.email || getEmailError()}
          info={
            !formData.email && !isAdminClientId
              ? t("userForm.description.emailRecommend")
              : undefined
          }
        >
          <BaseInputEmail
            name="email"
            value={formData.email}
            onChange={onChangeHandler}
            error={!!errors?.email || !!getEmailError()}
            placeholder={t("userForm.inputLabel.email")}
            required={requiredFields?.email}
            onRefresh={onRepeatVerifiedEmail}
            isContextBtnDisabled={isRepeatVerifiedEmailLoading}
            hidePartially={hidePersonalData}
            disabled={disabled}
            onFocus={() =>
              hidePersonalData && onChangeHandler({ name: "email", value: "" })
            }
          />
        </FormControl>
        <FormControl
          messageSlot={!isAdminClientId ? SupportDescription : undefined}
          error={errors?.phoneNumber}
        >
          <BaseInputPhone
            error={!!errors?.phoneNumber}
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={onChangeHandler}
            disabled={isPhoneNumberDisabled || disabled}
            placeholder={t("userForm.inputLabel.phoneNumber")}
            required={requiredFields?.phoneNumber}
            hidePartially={hidePersonalData}
          />
        </FormControl>
      </div>

      {!isAdmin && (
        <>
          <h3 className={styles.fieldsetTitle}>
            {t("userForm.fieldset.birthPlace")}
          </h3>
          <div className={styles.grid}>
            {!!countriesSelectItems.length && (
              <FormControl error={errors?.country}>
                <BaseSelect
                  activeItem={formData.country}
                  placeholder={t("userForm.inputLabel.country")}
                  error={!!errors?.country}
                  items={countriesSelectItems}
                  name="country"
                  onChange={onChangeHandler}
                  required={requiredFields?.country}
                  canSearch
                />
              </FormControl>
            )}
            {!!regionsSelectItems.length && (
              <FormControl error={errors?.region}>
                <BaseSelect
                  activeItem={formData.region}
                  placeholder={t("userForm.inputLabel.region")}
                  error={!!errors?.region}
                  items={regionsSelectItems}
                  name="region"
                  onChange={onChangeHandler}
                  required={requiredFields?.region}
                  canSearch
                />
              </FormControl>
            )}
            {!!districtsSelectItems.length && (
              <FormControl error={errors?.district}>
                <BaseSelect
                  activeItem={formData.district}
                  placeholder={t("userForm.inputLabel.district")}
                  error={!!errors?.district}
                  items={districtsSelectItems}
                  name="district"
                  onChange={onChangeHandler}
                  required={requiredFields?.district}
                  canSearch
                />
              </FormControl>
            )}
            <FormControl error={errors?.locality}>
              <BaseInputText
                value={formData.locality}
                placeholder={t("userForm.inputLabel.locality")}
                error={!!errors?.locality}
                name="locality"
                onChange={onChangeHandler}
                required={requiredFields?.locality}
              />
            </FormControl>
          </div>
        </>
      )}

      {((isPlayer && !!schoolsSelectItems.length) || !isAdmin) && (
        <>
          <h3 className={styles.fieldsetTitle}>
            {t("userForm.fieldset.education")}
          </h3>
          <div className={styles.grid}>
            {isPlayer && !!schoolsSelectItems.length && (
              <FormControl error={errors?.school}>
                <BaseSelect
                  activeItem={formData.school}
                  placeholder={t("userForm.inputLabel.school")}
                  error={!!errors?.school}
                  items={schoolsSelectItems}
                  name="school"
                  onChange={onChangeHandler}
                  required={requiredFields?.school}
                  canSearch
                />
              </FormControl>
            )}
            {!isAdmin && (
              <FormControl error={errors?.studyWorkPlace}>
                <BaseInputText
                  error={!!errors?.studyWorkPlace}
                  name="studyWorkPlace"
                  value={formData.studyWorkPlace}
                  onChange={onChangeHandler}
                  placeholder={t("userForm.inputLabel.studyWorkPlace")}
                  maxLength={50}
                  required={requiredFields?.studyWorkPlace}
                />
              </FormControl>
            )}
          </div>
        </>
      )}

      {isHr && (
        <>
          <h3 className={styles.fieldsetTitle}>
            {t("userForm.fieldset.workContacts")}
          </h3>
          <div className={styles.grid}>
            <FormControl error={errors?.workPhoneNumber}>
              <BaseInputPhone
                error={!!errors?.workPhoneNumber}
                name="workPhoneNumber"
                value={formData.workPhoneNumber}
                onChange={onChangeHandler}
                placeholder={t("userForm.inputLabel.workPhoneNumber")}
                required={requiredFields?.workPhoneNumber}
                hidePartially={hidePersonalData}
              />
            </FormControl>
            <FormControl error={errors?.supervisedUnit}>
              <BaseInputText
                error={!!errors?.supervisedUnit}
                name="supervisedUnit"
                value={formData.supervisedUnit}
                onChange={onChangeHandler}
                placeholder={t("userForm.inputLabel.supervisedUnit")}
                maxLength={50}
                required={requiredFields?.supervisedUnit}
              />
            </FormControl>
          </div>
        </>
      )}

      {isPlayer && (
        <>
          <h3 className={styles.fieldsetTitle}>
            {t("userForm.fieldset.direction")}
          </h3>

          <div className={styles.grid}>
            <FormControl error={errors?.desiredStudyPlace}>
              <BaseSelect
                error={!!errors?.desiredStudyPlace}
                activeItem={formData.desiredStudyPlace}
                placeholder={t("userForm.inputLabel.desiredStudyPlace")}
                items={desiredStudyPlaceItems}
                name="desiredStudyPlace"
                onChange={onChangeHandler}
                callback={cleanDesiredSpecialty}
                required={requiredFields?.desiredStudyPlace}
                canSearch
                disabled={formData.isStudent || formData.isEmployee}
                hideEmptyItem={true}
                isTopPosition
              />
            </FormControl>
            <FormControl error={errors?.groupCode}>
              <BaseInputText
                value={formData.groupCode}
                placeholder={t("userForm.inputLabel.groupCode")}
                error={!!errors?.groupCode}
                name="groupCode"
                onChange={onChangeHandler}
                required={requiredFields?.groupCode}
              />
            </FormControl>
            {isAlabugaStudent && (
              <FormControl
                description={t("userForm.multiSelect.infoMessage")}
                error={errors?.desiredSpecialty}
              >
                <BaseMultiSelect
                  error={!!errors?.desiredSpecialty}
                  activeItems={formData.desiredSpecialty}
                  name="desiredSpecialty"
                  items={desiredSpecialtyItems}
                  placeholder={t("userForm.inputLabel.desiredSpecialty")}
                  onChange={onChangeHandler}
                  required={requiredFields?.desiredSpecialty}
                  isTopPosition
                />
              </FormControl>
            )}
            {formData.desiredStudyPlace && !isWithoutCheckbox && (
              <>
                {isAlabugaStudent ? (
                  <div className={styles.checkbox}>
                    <InputCheckbox
                      type={Checkbox.CHOSEN}
                      label={t("userForm.checkbox.label.student")}
                      value={formData.isStudent}
                      isDisabled={confirmedStudent}
                      onChange={(item) =>
                        onChangeHandler({
                          name: "isStudent",
                          value: item,
                        })
                      }
                    />
                  </div>
                ) : (
                  <div className={styles.checkbox}>
                    <InputCheckbox
                      type={Checkbox.CHOSEN}
                      label={t("userForm.checkbox.label.employee")}
                      value={formData.isEmployee}
                      isDisabled={confirmedEmployee}
                      onChange={(item) =>
                        onChangeHandler({
                          name: "isEmployee",
                          value: item,
                        })
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}

      {isReCaptchaShow && (
        <SmartCaptcha
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""}
          language={i18n.language as "ru" | "en"}
          onSuccess={(params) => {
            params && setRecaptchaToken?.(params);
          }}
          onTokenExpired={() => {
            setRecaptchaToken?.("");
          }}
          onNetworkError={() => {
            setRecaptchaToken?.("");
          }}
        />
      )}
    </form>
  );
};

const {
  $countriesSelectItems,
  $regionsSelectItems,
  $districtsSelectItems,
  $schoolsSelectItems,
  $isPlayer,
  $isHr,
  $isAdmin,
  resetFormData,
  resetUserType,
} = userFormModel;

const { $isAdminClientId } = keycloakModel;

export const UserForm = reflect<Props>({
  view: View,
  bind: {
    countriesSelectItems: $countriesSelectItems,
    regionsSelectItems: $regionsSelectItems,
    districtsSelectItems: $districtsSelectItems,
    schoolsSelectItems: $schoolsSelectItems,
    isPlayer: $isPlayer,
    isHr: $isHr,
    isAdmin: $isAdmin,
    isAdminClientId: $isAdminClientId,
  },
  hooks: {
    unmounted: () => {
      resetFormData();

      resetUserType();
    },
  },
});
