import {
  FC,
  MutableRefObject,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useTranslation } from "react-i18next";

import { useUnit } from "effector-react";

import { getCompetenceWithoutTenacity } from "src/shared/helpers";

import { useMediaQuery } from "src/shared/hooks/useMediaQuery";
import { v4 as uuidv4 } from "uuid";

import {
  BaseBanner,
  BaseTooltip,
  InformationPopup,
  LvlRatingItem,
  PageWrapper,
  TextWithIcon,
} from "src/shared/components";

import { EPopupName, popupModel } from "src/shared/components/base-popup";

import { LvlUpBlock } from "./components";

import "./my-competence-page.scss";
import { PlayerCompetenceRate } from "../../../generated/social";
import { isMainCompetence } from "../../../shared/helpers/isMainCompetence";
import classNames from "classnames";
import useClient from "../../../shared/hooks/useClient";
import { appAccessModel } from "src/features/public/app-access";
import { SessionsHistoryPlayer } from "../../../shared/components/session-history-player/session-history-player";
import { Navigation, SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  $fineStatus,
  $socialPlayerInfo,
  getFines,
} from "../../../features/public/app-access/model";
import { FineWarningText } from "./components/fine-warning-text/fine-warning-text";
import { useNotifications } from "../../../shared/hooks/useNotifications";
import { FineNotification, MsgType } from "../../../generated/notification";
import {
  $isLoadingAgreementConfirmed,
  $isLoadingCompetence,
  $places,
  getPlaces,
} from "../../../features/public/app-access/model/user-place";
import { MyCompetencePageSkeleton } from "./components/loader/my-competence-page-skeleton";
import { $keycloak } from "../../../entities/public/keycloak/model";
import { JoinByCode } from "./components/join-by-code/join-by-code";

interface Props {}

const { openPopup } = popupModel;

export const MyCompetencePage: FC<Props> = (): ReactElement => {
  const { t } = useTranslation();

  const { isMobile } = useMediaQuery();

  const socialPlayerInfo = useUnit($socialPlayerInfo);

  const fineStatus = useUnit($fineStatus);

  const places = useUnit($places);

  const keycloak = useUnit($keycloak);

  const isCompetencesLoading = useUnit($isLoadingCompetence);

  const isAgreementLoading = useUnit($isLoadingAgreementConfirmed);

  const { newItems } = useNotifications();

  const { isPlayerClientId } = useClient();

  const newFineNotify = newItems.find(
    (item) => item.type === MsgType.FineNotification,
  );

  const isVerificationSession = useUnit(appAccessModel.$isVerificationSession);

  const isLoading = isCompetencesLoading || isAgreementLoading;

  const filteredCompetence = useMemo(() => {
    return getCompetenceWithoutTenacity(places?.competenceRates ?? []);
  }, [places?.competenceRates]);

  const mainCompetence = useMemo(() => {
    return filteredCompetence.filter(({ competence }) =>
      isMainCompetence(competence),
    );
  }, [filteredCompetence]);

  const tooltipToggleRef: MutableRefObject<null> = useRef(null);

  const additionalCompetence = useMemo(() => {
    return filteredCompetence.filter(
      ({ competence }) => !isMainCompetence(competence),
    );
  }, [filteredCompetence]);

  useEffect(() => {
    if (newFineNotify) {
      const item: FineNotification = newFineNotify.msg as FineNotification;
      openPopup({
        name: EPopupName.FINE_FOR_INACTION_POPUP,
        data: {
          periodList: item.period,
          ratingList: item.removable_rating,
          competenceList: item.competencies,
        },
      });
    }
  }, [newFineNotify]);

  useEffect(() => {
    if (keycloak?.subject) {
      getPlaces({ pId: keycloak.subject });
      getFines({ pId: keycloak.subject });
    }
  }, [keycloak?.subject]);

  const sliderProps: SwiperOptions = useMemo(
    () => ({
      navigation: true,
      slidesPerView: "auto",
      spaceBetween: isMobile ? 16 : 24,
      effect: isMobile ? "coverflow" : undefined,
      coverflowEffect: isMobile
        ? {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }
        : undefined,
    }),
    [isMobile],
  );

  const showInformationPopup = () => {
    openPopup({ name: EPopupName.INFORMATION_POPUP });
  };

  const getCompetenceContent = (
    item: PlayerCompetenceRate,
    isMainCompetences: boolean,
  ) => {
    const { competence, rate, diff, place } = item;
    return (
      <div
        key={uuidv4()}
        className={classNames(
          "my-competence-page__list-item",
          `my-competence-page__list-item--${competence}`,
          {
            "my-competence-page__list-item--additional": !isMainCompetences,
          },
        )}
      >
        <LvlRatingItem
          title={t(`profile.competence.title.${competence}`)}
          subTitle={t("profile.competence.lbl.level")}
          rate={rate || 0}
          diff={diff || 0}
          competence={competence}
          place={place}
          placeTotal={String(places?.gPlaceTotal)}
          isMainCompetence={isMainCompetences}
        />
      </div>
    );
  };
  return (
    <PageWrapper
      emptyPanel
      isWithoutPadding
      customContentClassName="page-wrapper__content--my-competence-page"
    >
      <div className="my-competence-page">
        {isLoading ? (
          <MyCompetencePageSkeleton />
        ) : (
          <>
            {isPlayerClientId && socialPlayerInfo && !isVerificationSession && (
              <BaseBanner />
            )}
            <div className="my-competence-page__lvl-up-wrapper">
              <LvlUpBlock className="my-competence-page__lvl-up" />
              <JoinByCode />
            </div>
            <div className="my-competence-page__list-wrapper">
              <div className="my-competence-page__list-competence-group-name">
                {t("profile.competence.group.main.title")}
                {fineStatus &&
                  Math.min(...Object.values(fineStatus.period)) <= 5 && (
                    <div
                      className="my-competence-page-fine-warning tooltip-wrapper"
                      ref={tooltipToggleRef}
                    >
                      <TextWithIcon
                        iconSize={38}
                        iconName="warning-red"
                        hideLabel
                      />
                      <BaseTooltip
                        position={isMobile ? "bottom-end" : "bottom-start"}
                        tooltipToggleRef={tooltipToggleRef}
                      >
                        <FineWarningText
                          days={fineStatus.period}
                          rating={fineStatus.removable_rating}
                          competencies={fineStatus.competencies}
                        />
                      </BaseTooltip>
                    </div>
                  )}
              </div>
              <div className="my-competence-page__list">
                {mainCompetence.map((competence) => {
                  return getCompetenceContent(competence, true);
                })}
              </div>
              <div className="my-competence-page__list-competence-group-name my-competence-page__list-competence-group-name--additional">
                {t("profile.competence.group.additional.title")}
              </div>
              <div className="my-competence-page__list my-competence-page__list--additional">
                {!!additionalCompetence.length && (
                  <>
                    <Swiper
                      modules={[Navigation]}
                      className="my-competence-page__list-items-wrapper-additional"
                      {...sliderProps}
                    >
                      {additionalCompetence.map((competence, index) => {
                        return (
                          <SwiperSlide
                            className={classNames(
                              "my-competence-page__list-items-wrapper-additional__item",
                              `my-competence-page__list-items-wrapper-additional__item--${competence.competence}`,
                            )}
                            key={index}
                          >
                            {getCompetenceContent(competence, false)}
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </>
                )}
              </div>
            </div>
            <button
              className="my-competence-page__information-btn"
              onClick={showInformationPopup}
            />
            <SessionsHistoryPlayer />
            <InformationPopup />
          </>
        )}
      </div>
    </PageWrapper>
  );
};
