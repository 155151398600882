import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BaseButton, TextWithIcon } from "src/shared/components";

interface Props {
  userId?: string;
}

export const UserInfoHeaderSlot: FC<Props> = ({ userId }) => {
  const { t } = useTranslation();

  return (
    <div className="table__control">
      <BaseButton
        className="page-wrapper__title-sub-item"
        lightPurple
        submit={false}
        to={`/users-control/user/${userId}/sessions-history`}
      >
        <TextWithIcon
          iconName="history-blue"
          iconSize={22}
          hideLabelOnMobile
          label={t("sessionsHistory.title")}
        />
      </BaseButton>
      <BaseButton
        className="page-wrapper__title-sub-item"
        lightPurple
        submit={false}
        to={`/users-control/user/${userId}/tutorial-history`}
      >
        <TextWithIcon
          iconName="history-blue"
          iconSize={22}
          hideLabelOnMobile
          label={t("tutorialHistory.title")}
        />
      </BaseButton>
      <BaseButton
        className="page-wrapper__title-sub-item"
        lightPurple
        submit={false}
        to={`/users-control/user/${userId}/fines-history`}
      >
        <TextWithIcon
          iconName="history-blue"
          iconSize={22}
          hideLabelOnMobile
          label={t("fineHistory.title")}
        />
      </BaseButton>
      <BaseButton
        className="page-wrapper__title-sub-item"
        lightPurple
        submit={false}
        to={`/users-control/user/${userId}/complaints-history`}
      >
        <TextWithIcon
          iconName="history-blue"
          iconSize={22}
          hideLabelOnMobile
          label={t("complaintsHistory.title")}
        />
      </BaseButton>
    </div>
  );
};
