import { PlayersApiGetCanEditPlayerRequest } from "src/generated/social";
import { keycloakService, socialService } from "../../../base/api-instances";
import {
  AccountApiSendVerifyEmailRequest,
  UserReprAcc,
} from "src/generated/keycloak";

export const getProfileInfo = () => {
  return keycloakService
    .makeAccountRequest()
    .getAccount()
    .then((response) => response.data);
};

export const postProfileInfo = (params: UserReprAcc) => {
  return keycloakService
    .makeAccountRequest()
    .updateAccount({ userReprAcc: params })
    .then((response) => response.data);
};

export const sendVerifyEmail = (params: AccountApiSendVerifyEmailRequest) => {
  return keycloakService
    .makeAccountRequest()
    .sendVerifyEmail(params)
    .then((response) => response.data);
};

export const getCanEditPlayer = (params: PlayersApiGetCanEditPlayerRequest) => {
  return socialService
    .makePlayerRequest()
    .getCanEditPlayer(params)
    .then((response) => response.data);
};
