import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestAddResume,
  requestAddUserAvatar,
  requestDeleteResume,
  requestDeleteUserAvatar,
  selectCdnOptionsResumeStatus,
  selectCdnUserAvatarStatus,
} from "src/shared/store/ducks/cdn";
import {
  getAvatarUrl,
  getUserFormData,
  toCloneObject,
} from "src/shared/helpers";
import { ERequestStatus } from "../store/types";
import { getResumePreview } from "../components/resume-upload/lib/resume";
import { FormDataInterface } from "../components";
import { UserReprAcc } from "../../generated/keycloak";
import { Player, StatusCompanyEnum } from "../../generated/social";
import { updateProfileById } from "../../features/public/app-access/model/patch-profile";

interface ProfileDataProps {
  userId: string;
  socialPlayerInfo: Player;
}

type TFilesNames = "avatar" | "resume";

interface IUploadFileItem {
  file?: File;
  isLoaded: boolean;
}

type TUploadFiles = { [key in TFilesNames]: IUploadFileItem };

const initialUploadFilesState: TUploadFiles = {
  avatar: {
    isLoaded: false,
  },
  resume: {
    isLoaded: false,
  },
};

const useProfileData = ({ userId, socialPlayerInfo }: ProfileDataProps) => {
  const dispatch = useDispatch();
  const loadingAvatar = useSelector(selectCdnUserAvatarStatus);
  const loadingResume = useSelector(selectCdnOptionsResumeStatus);

  const [formData, setFormData] = useState<FormDataInterface>(
    getUserFormData(),
  );

  const [uploadFiles, setUploadFiles] = useState<TUploadFiles>(
    initialUploadFilesState,
  );

  const onSaveStatusCompany = () => {
    const localStudentStatus = formData.isStudent;
    const localEmployeeStatus = formData.isEmployee;
    const serverStudentStatus = socialPlayerInfo.is_student;
    const serverEmployeeStatus = socialPlayerInfo.is_employee;

    if (localStudentStatus !== serverStudentStatus) {
      updateProfileById({
        pId: userId,
        body: {
          statusCompany: localStudentStatus
            ? StatusCompanyEnum.CandidateStudent
            : StatusCompanyEnum.NoSelected,
        },
      });
    }
    if (localEmployeeStatus !== serverEmployeeStatus) {
      if (localStudentStatus === true) {
        return null;
      }
      updateProfileById({
        pId: userId,
        body: {
          statusCompany: localEmployeeStatus
            ? StatusCompanyEnum.CandidateEmployee
            : StatusCompanyEnum.NoSelected,
        },
      });
    }
  };

  useEffect(() => {
    if (loadingAvatar === ERequestStatus.LOADED) {
      setFormData((prev) => ({
        ...prev,
        avatarUrl: `${getAvatarUrl(userId)}?${new Date().getTime()}`,
      }));
      onToggleFileLoadedHandler("avatar", true);
    }
  }, [loadingAvatar, userId]);

  useEffect(() => {
    if (loadingResume === ERequestStatus.LOADED) {
      onToggleFileLoadedHandler("resume", true);
      getResumePreview(userId);
    }
  }, [loadingResume, userId]);

  const onToggleFileLoadedHandler = (
    fileName: "avatar" | "resume",
    isLoaded = false,
  ) => {
    setUploadFiles((prev) => {
      const localPrev = toCloneObject(prev);

      localPrev[fileName].isLoaded = isLoaded;

      return localPrev;
    });
  };

  const onSaveAvatarHandler = (profileInfo: UserReprAcc): void => {
    const avatarFile: File | undefined = uploadFiles.avatar.file;
    const isNotAvatar: boolean =
      !avatarFile &&
      !formData.avatarUrl &&
      !!profileInfo?.attributes?.avatarUrl?.[0];

    if (avatarFile) {
      const callback = () => {
        setFormData((prev) => {
          const localPrev = toCloneObject(prev);
          if (localPrev) {
            localPrev.avatarUrl = `${getAvatarUrl(
              userId,
            )}?${new Date().getTime()}`;
          }

          return localPrev;
        });

        onToggleFileLoadedHandler("avatar", true);
      };

      dispatch(
        requestAddUserAvatar({
          options: {
            id: userId,
            file: avatarFile,
          },
          callback,
        }),
      );
    } else if (isNotAvatar) {
      const callback = () => {
        setFormData((prev) => {
          const localPrev = toCloneObject(prev);
          if (localPrev) {
            localPrev.avatarUrl = "";
          }

          return localPrev;
        });

        onToggleFileLoadedHandler("avatar", true);
      };

      dispatch(
        requestDeleteUserAvatar({
          options: {
            id: userId,
          },
          callback,
        }),
      );
    } else {
      onToggleFileLoadedHandler("avatar", true);
    }
  };

  const onSaveResumeHandler = (profileInfo: UserReprAcc) => {
    const resumeFile: File = uploadFiles.resume.file!;
    const isNotResume: boolean =
      !resumeFile &&
      !formData.resumeFileName &&
      !!profileInfo?.attributes?.resumeFileName?.[0];

    if (resumeFile) {
      const callback = () => {
        onToggleFileLoadedHandler("resume", true);
      };

      dispatch(
        requestAddResume({
          options: {
            id: userId,
            file: uploadFiles.resume.file,
          },
          callback,
        }),
      );
    } else if (isNotResume) {
      const callback = () => {
        setFormData((prev) => {
          const localPrev = toCloneObject(prev);

          if (localPrev) {
            localPrev.resumeFileName = "";
          }

          return localPrev;
        });

        onToggleFileLoadedHandler("resume", true);
      };

      dispatch(
        requestDeleteResume({
          options: {
            id: userId,
          },
          callback,
        }),
      );
    } else {
      onToggleFileLoadedHandler("resume", true);
    }
  };

  const onChangeAvatarHandler = (file: File | undefined, avatar: string) => {
    setFormData((prev) => {
      const localPrev = toCloneObject(prev);
      if (localPrev) {
        localPrev.avatarUrl = avatar || "";
      }

      return localPrev;
    });

    setUploadFiles((prev) => {
      const localPrev = toCloneObject(prev);

      localPrev.avatar.file = file;

      return localPrev;
    });
  };

  const onChangeResumeHandler = (
    file: File | undefined,
    resumeName: string,
  ) => {
    setFormData((prev) => ({
      ...prev,
      resumeFileName: resumeName || "",
    }));

    setUploadFiles((prev) => ({
      ...prev,
      resume: {
        ...prev.resume,
        file,
      },
    }));
  };

  return {
    formData,
    uploadFiles,
    onSaveAvatarHandler,
    onSaveResumeHandler,
    onChangeAvatarHandler,
    onChangeResumeHandler,
    onToggleFileLoadedHandler,
    onSaveStatusCompany,
    setUploadFiles,
    setFormData,
  };
};

export default useProfileData;
