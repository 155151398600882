import { FC } from "react";
import { UserRights } from "src/features/hr/account/ui/user-rights/user-rights";
import {
  Permissions,
  useCheckPermission,
} from "src/shared/hooks/useCheckPermission";

interface Props {
  subjectUserId?: string;
  isSubjectHr: boolean;
}

export const RightsSection: FC<Props> = ({ subjectUserId, isSubjectHr }) => {
  const specificPermission = useCheckPermission(Permissions.WritePermissions);

  const clientIdToModal = isSubjectHr ? "hr" : "admin";

  return (
    <UserRights
      hasPermission={specificPermission}
      userId={subjectUserId}
      clientIdToModal={clientIdToModal}
    />
  );
};
