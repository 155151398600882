import { ClipboardEvent, FC, useId, useState } from "react";
import styles from "./code-input.module.scss";
import classNames from "classnames";

interface Props {
  value?: string;
  onChange?: (newValue: string) => void;
}

export const CodeInput: FC<Props> = ({ value = "", onChange }) => {
  const id = useId();

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const pasteHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const text = event.clipboardData.getData("text");

    onChange?.((value + text.replace(/\D/g, "")).slice(0, 6));
  };

  return (
    <div className={styles.wrapper}>
      <input
        value={value}
        onChange={(e) => onChange?.(e.target.value.replace(/\D/g, ""))}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        maxLength={6}
        className={styles.input}
        id={id}
        onPaste={pasteHandler}
        inputMode="numeric"
      />
      <div
        className={classNames(styles.input_part, {
          [styles.active]: value.length === 0 && isFocused,
        })}
      >
        {value[0]}
      </div>
      <div
        className={classNames(styles.input_part, {
          [styles.active]: value.length === 1 && isFocused,
        })}
      >
        {value[1]}
      </div>
      <div
        className={classNames(styles.input_part, {
          [styles.active]: value.length === 2 && isFocused,
        })}
      >
        {value[2]}
      </div>

      <span className={styles.delimiter}>-</span>

      <div
        className={classNames(styles.input_part, {
          [styles.active]: value.length === 3 && isFocused,
        })}
      >
        {value[3]}
      </div>
      <div
        className={classNames(styles.input_part, {
          [styles.active]: value.length === 4 && isFocused,
        })}
      >
        {value[4]}
      </div>
      <div
        className={classNames(styles.input_part, {
          [styles.active]: value.length >= 5 && isFocused,
        })}
      >
        {value[5]}
      </div>
    </div>
  );
};
