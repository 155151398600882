import { SmartCaptcha } from "@yandex/smart-captcha";
import { FC, memo, ReactElement } from "react";

import { BasePopup, EPopupName } from "src/shared/components";
import i18n from "src/shared/i18n/i18n";

interface Props {
  setCaptchaToken: (token?: string) => void;
}

export const CaptchaPopup: FC<Props> = memo(
  ({ setCaptchaToken }): ReactElement => {
    return (
      <BasePopup name={EPopupName.CAPTCHA}>
        <SmartCaptcha
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ""}
          language={i18n.language as "ru" | "en"}
          onSuccess={(params) => {
            params && setCaptchaToken(params);
          }}
          onTokenExpired={() => {
            setCaptchaToken("");
          }}
          onNetworkError={() => {
            setCaptchaToken("");
          }}
        />
      </BasePopup>
    );
  },
);
