import styles from "./recursive-rights.module.scss";

import { FC } from "react";
import { PermissionDto } from "src/generated/social";
import { BaseInputRadio, InputCheckbox } from "src/shared/components";
import { Checkbox } from "src/shared/models";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
  rights: PermissionDto[];
  isChangeable: boolean;
  onChange: (right: PermissionDto) => void;
}

const RecursiveRights: FC<Props> = ({ rights, isChangeable, onChange }) => {
  const { t } = useTranslation();

  const getSelectedOrDefault = (group: string) => {
    const groupRigths = rights.filter((right) => right.group === group);

    const selected = groupRigths.find((right) => right.selected);

    return (
      selected?.name || groupRigths.find((right) => right.is_default)?.name
    );
  };

  return (
    <div className={styles.rightsWrapper}>
      {rights.map((right, index) => (
        <div className={styles.rights} key={(right.name as string) + index}>
          {right.type === "checkbox" && (
            <div className={styles.right}>
              <InputCheckbox
                onChange={() => onChange(right)}
                value={right.is_enabled || right.is_default}
                type={Checkbox.CHOSEN}
                isDisabled={!isChangeable}
                label=""
              />
              <span
                className={classNames(styles.rightName, {
                  [styles.disabled]: !isChangeable,
                })}
                onClick={() => isChangeable && onChange(right)}
              >
                {t(`permission.main.permission-name.${right.name}`)}
              </span>
            </div>
          )}

          {right.type === "group_radio" && (
            <div className={classNames(styles.right, styles.right_radio)}>
              <BaseInputRadio
                value={right.name}
                activeValue={getSelectedOrDefault(right.group as string) ?? ""}
                name={right.name}
                label=""
                onChange={() => onChange(right)}
                isDisabled={!isChangeable}
              />
              <span
                className={classNames(styles.rightName, {
                  [styles.disabled]: !isChangeable,
                })}
                onClick={() => isChangeable && onChange(right)}
              >
                {t(`permission.main.permission-name.${right.name}`)}
              </span>
            </div>
          )}

          {!!right.children?.length && right.is_enabled && (
            <div className={styles.subrights}>
              <RecursiveRights
                rights={right.children}
                isChangeable={isChangeable}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default RecursiveRights;
